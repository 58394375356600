import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import Header from './components/header/Header';
import AuthPage from './components/auth/AuthPage';
import TwoFactorAuth from './components/login/TwoFactorAuth';
import { RootState, AppDispatch } from './app/store';
import { isTokenValid, refreshToken } from './features/auth/authSlice';
import './App.css';
import Directories from './pages/directories/Directories';
import Applications from "./pages/applications/Applications";
import Ligaments from "./pages/Ligaments";
import axios from 'axios';
// @ts-ignore
import styles from './pages/directories/Directories.module.css';
import { API_URL } from "./features/auth/authSlice";
import ErrorModal from './components/ErrorModal';
import MasageModal from './components/MasageModal';
import RegistrationSuccess from './components/registration/RegistrationSuccess';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 403) {
      console.error('Недостаточно прав для выполнения операции');
    }
    return Promise.reject(error);
  }
);

async function executeRequestWithTokenRefresh(requestFunction: () => Promise<any>) {
  try {
    return await requestFunction();
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${API_URL}/auth/refresh`, { refresh_token: refreshToken });
        localStorage.setItem('accessToken', response.data.access_token);
        return await requestFunction();
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        throw refreshError;
      }
    }
    throw error;
  }
}

const DownloadReport: React.FC = () => {
  const [isDownloading, setIsDownloading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const downloadReport = async () => {
      try {
        await executeRequestWithTokenRefresh(async () => {
          const accessToken = localStorage.getItem('accessToken');
          if (!accessToken) {
            throw new Error('Access token not found');
          }

          const response = await axios.get(`${API_URL}/excel/download/converted_report`, {
            responseType: 'blob',
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          const filename = response.headers['content-disposition']?.split('filename=')[1]?.trim().replace(/^"|"$/g, '') || 'report.xlsx';
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      } catch (error) {
        console.error('Error downloading report:', error);
      } finally {
        setIsDownloading(false);
        navigate('/');
      }
    };

    downloadReport();
  }, [navigate]);

  return isDownloading ? (
    <div className={styles.loadingOverlay}>
      <div className={styles.loadingSpinner}></div>
      <h3>Скачивание отчета...</h3>
      <p>Пожалуйста, не закрывайте и не обновляйте страницу</p>
    </div>
  ) : null;
};

const ActivateAccount: React.FC = () => {
  const [isActivating, setIsActivating] = useState(true);
  const [activationError, setActivationError] = useState<string | null>(null);
  const [activationSuccess, setActivationSuccess] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const activateAccount = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');

      console.log('Activation token:', token);

      if (!token) {
        setActivationError('Токен активации отсутствует');
        setIsActivating(false);
        return;
      }

      try {
        console.log('Sending activation request...');
        const response = await axios.get(
          `${API_URL}/auth/activate/${token}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            validateStatus: function (status) {
              return status < 500;
            }
          }
        );
        console.log('Activation response:', response);

        if (response.status === 200) {
          setActivationSuccess('Аккаунт успешно активирован');
        } else {
          throw new Error(response.data.message || 'Неизвестная ошибка при активации аккаунта');
        }
      } catch (error: any) {
        console.error('Ошибка при активации аккаунта:', error);
        if (error.response) {
          console.error('Error response:', error.response);
          setActivationError(error.response.data.message || 'Не удалось активировать аккаунт. Пожалуйста, попробуйте снова или обратитесь в поддержку.');
        } else if (error.request) {
          console.error('Error request:', error.request);
          setActivationError('Не удалось отправить запрос на активацию. Проверьте ваше интернет-соединение.');
        } else {
          setActivationError(error.message || 'Произошла неизвестная ошибка при активации аккаунта.');
        }
      } finally {
        setIsActivating(false);
      }
    };

    activateAccount();
  }, [location]);

  if (isActivating) {
    return (
      <div className={styles.loadingOverlay}>
        <div className={styles.loadingSpinner}></div>
        <h3>Активация аккаунта...</h3>
        <p>Пожалуйста, подождите</p>
      </div>
    );
  }

  return (
    <>
      {activationError && <ErrorModal message={activationError} onClose={() => navigate('/login')} />}
      {activationSuccess && <MasageModal message={activationSuccess} onClose={() => navigate('/login')} />}
    </>
  );
};

function App() {
  const { user, accessToken, refreshToken: refreshTokenValue, require2FA, qrCodeUri } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkTokens = async () => {
      const storedAccessToken = localStorage.getItem('accessToken');
      const storedRefreshToken = localStorage.getItem('refreshToken');

      const isRegistrationSuccess = location.pathname === '/registration-success';

      if (storedAccessToken && isTokenValid(storedAccessToken)) {
        dispatch({ type: 'auth/setTokens', payload: { accessToken: storedAccessToken, refreshToken: storedRefreshToken } });
        if (location.pathname === '/login' && !isRegistrationSuccess) {
          navigate('/');
        }
      } else if (storedRefreshToken) {
        await dispatch(refreshToken(storedRefreshToken));
        if (location.pathname === '/login' && !isRegistrationSuccess) {
          navigate('/');
        }
      } else if (location.pathname !== '/login' && location.pathname !== '/registration-success' && !require2FA) {
        navigate('/login');
      }
      setIsLoading(false);
    };

    checkTokens();
  }, [dispatch, navigate, location, require2FA]);

  useEffect(() => {
    if (accessToken && !isTokenValid(accessToken) && refreshTokenValue) {
      dispatch(refreshToken(refreshTokenValue));
    }
  }, [accessToken, refreshTokenValue, dispatch]);

  const isAuthenticated = accessToken && isTokenValid(accessToken);

  useEffect(() => {
    if (isAuthenticated && location.pathname === '/login' && !location.search.includes('registration=true')) {
      navigate('/');
    }
  }, [isAuthenticated, location.pathname, navigate, location.search]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Header />
      <div className='Routes'>
        <Routes>
          <Route path="/login" element={
            isAuthenticated ? (
                <Navigate to="/" replace />
            ) : (
                <>
                  {!require2FA && !qrCodeUri && <AuthPage />}
                  {(require2FA || qrCodeUri) && <TwoFactorAuth />}
                </>
            )
          } />
          <Route path="/ligaments" element={isAuthenticated ? <Ligaments /> : <Navigate to="/login" replace />} />
          <Route path="/applications" element={isAuthenticated ? <Applications /> : <Navigate to="/login" replace />} />
          <Route path="/directories" element={isAuthenticated ? <Directories /> : <Navigate to="/login" replace />} />
          <Route path="/download_report" element={isAuthenticated ? <DownloadReport /> : <Navigate to="/login" replace />} />
          <Route path="/activate" element={<ActivateAccount />} />
          <Route path="/registration-success" element={<RegistrationSuccess />} />
          <Route path="/" element={
            isAuthenticated ? (
                <div className='body'>
                  <Directories />
                </div>
            ) : (
                <Navigate to="/login" replace />
            )
          } />
        </Routes>
      </div>
    </div>
  );
}

export default App;
