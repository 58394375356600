import React, { useState } from 'react';
import Login from '../login/Login';
import Register from '../login/Register';

const AuthPage: React.FC = () => {
  const [isLoginView, setIsLoginView] = useState(true);

  const switchToRegister = () => setIsLoginView(false);
  const switchToLogin = () => setIsLoginView(true);

  return (
    <div>
      {isLoginView 
        ? <Login onSwitchToRegister={switchToRegister} />
        : <Register onSwitchToLogin={switchToLogin} />
      }
    </div>
  );
};

export default AuthPage;
