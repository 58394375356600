import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { API_URL } from '../features/auth/authSlice';
import styles from './UserForm.module.css';

const UserForm = ({ user, onChange, onRoleChange, onCreateUser, submitButtonText, roles, cancelButtonText, onCancel }) => {
    const [categoryKeys, setCategoryKeys] = useState([]);
    const [departmentHeads, setDepartmentHeads] = useState([]);
    const [categoryDirectors, setCategoryDirectors] = useState([]);
    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        roles: ''
    });
    const [touched, setTouched] = useState({
        first_name: false,
        last_name: false,
        email: false,
        password: false,
        roles: false
    });
    const [createdUserId, setCreatedUserId] = useState(null);
    const [createdGroupId, setCreatedGroupId] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [userCreated, setUserCreated] = useState(false);
    const [newUserId, setNewUserId] = useState(null);
    const [groupId, setGroupId] = useState(null);
    const [initialGroupData, setInitialGroupData] = useState(null);

    useEffect(() => {
        if (!user.category_keys) {
            onChange({
                target: {
                    name: 'category_keys',
                    value: []
                }
            });
        }
    }, []);

    useEffect(() => {
        const fetchCategoryKeys = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axios.get(`${API_URL}/users/category_keys`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setCategoryKeys(response.data);
            } catch (error) {
                console.error('Error fetching category keys:', error);
            }
        };

        fetchCategoryKeys();
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axios.get(`${API_URL}/users/users`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                const heads = response.data.filter(user =>
                    user.roles.includes('CATEGORY_HEAD')
                ).map(user => ({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name}`
                }));

                const directors = response.data.filter(user =>
                    user.roles.includes('CATEGORY_DIRECTOR')
                ).map(user => ({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name}`
                }));

                setDepartmentHeads(heads);
                setCategoryDirectors(directors);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const updateGroupWithUser = async () => {
            if (userCreated && newUserId && groupId && initialGroupData) {
                console.log('Updating group after user creation...');
                const accessToken = localStorage.getItem('accessToken');

                const updateGroupData = {
                    name: initialGroupData.name,
                    processing_key: initialGroupData.processing_key,
                    escalation_manager_id: initialGroupData.escalation_manager_id,
                    escalation_director_id: initialGroupData.escalation_director_id,
                    escalation_days: initialGroupData.escalation_days,
                    user_ids: [newUserId]
                };

                try {
                    console.log('Sending PUT request to update group:', updateGroupData);
                    const updateResponse = await axios.put(
                        `${API_URL}/groups/groups/${groupId}`,
                        updateGroupData,
                        {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    );
                    console.log('Group successfully updated:', updateResponse.data);
                } catch (error) {
                    console.error('Error updating group:', error);
                }
            }
        };

        updateGroupWithUser();
    }, [userCreated, newUserId, groupId, initialGroupData]);

    const roleOptions = Object.entries(roles).map(([key, value]) => ({
        value: key,
        label: value
    }));

    const categoryKeyOptions = categoryKeys.map(key => ({
        value: key.id,
        label: key.key
    }));

    const handleCategoryKeyChange = (selectedOptions) => {
        if (!selectedOptions) {
            onChange({ 
                target: { 
                    name: 'category_keys', 
                    value: [] 
                } 
            });
            return;
        }

        const selectedIds = selectedOptions.map(option => option.value);

        const currentKeys = Array.isArray(user.category_keys) ? user.category_keys : [];
        const updatedKeys = [...new Set([...currentKeys, ...selectedIds])];
        
        onChange({ 
            target: { 
                name: 'category_keys', 
                value: updatedKeys 
            } 
        });
    };

    const handleDepartmentHeadChange = (selectedOption) => {
        const selectedId = selectedOption?.value || null;
        console.log('Setting department head ID:', selectedId);
        onChange({ target: { name: 'department_head_id', value: selectedId } });
    };

    const handleCategoryDirectorChange = (selectedOption) => {
        const selectedId = selectedOption?.value || null;
        console.log('Setting category director ID:', selectedId);
        onChange({ target: { name: 'category_director_id', value: selectedId } });
    };

    const handleEscalationDirectorChange = (selectedOption) => {
        onChange({
            target: {
                name: 'escalation_director_id',
                value: selectedOption?.value
            }
        });
    };

    const shouldShowField = (fieldName) => {
        const currentRole = user.roles;

        switch (fieldName) {
            case 'category_key':
                return ['SYSTEM', 'CATEGORY_HEAD', 'CATEGORY_DIRECTOR'].includes(currentRole);
            case 'CATEGORY_HEAD':
                return currentRole === 'SYSTEM';
            case 'category_director':
                return ['SYSTEM', 'CATEGORY_HEAD'].includes(currentRole);
            default:
                return false;
        }
    };

    const handleRemoveKey = (keyId) => {
        const updatedKeys = user.category_keys.filter(id => id !== keyId);
        onChange({
            target: {
                name: 'category_keys',
                value: updatedKeys
            }
        });
    };

    const validateField = (name, value) => {
        if (name === 'roles') {
            if (!value) {
                return 'Выберите роль';
            }
        } else if (!value?.trim()) {
            return 'Это поле обязательно для заполнения';
        }
        if (name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
            return 'Введите корректный email адрес';
        }
        return '';
    };

    const handleBlur = (e) => {
        const { name } = e.target;
        setTouched(prev => ({
            ...prev,
            [name]: true
        }));
        setErrors(prev => ({
            ...prev,
            [name]: validateField(name, user[name])
        }));
    };

    const handleRoleChange = (selectedOption) => {
        onRoleChange(selectedOption);
        setTouched(prev => ({
            ...prev,
            roles: true
        }));
        setErrors(prev => ({
            ...prev,
            roles: validateField('roles', selectedOption?.value)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = {
            first_name: validateField('first_name', user.first_name),
            last_name: validateField('last_name', user.last_name),
            email: validateField('email', user.email),
            password: validateField('password', user.password),
            roles: validateField('roles', user.roles)
        };

        setErrors(newErrors);
        setTouched({
            first_name: true,
            last_name: true,
            email: true,
            password: true,
            roles: true
        });

        if (Object.values(newErrors).some(error => error !== '')) {
            return;
        }

        try {
            let createdGroupId = null;
            if ((user.roles === 'SYSTEM' || user.roles === 'CATEGORY_HEAD') && user.category_keys?.length > 0) {
                const accessToken = localStorage.getItem('accessToken');
                const selectedKeys = categoryKeys.filter(key => user.category_keys.includes(key.id));

                if (selectedKeys.length > 0) {
                    const escalationResponse = await axios.get(`${API_URL}/groups/escalation`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });

                    const groupData = {
                        name: selectedKeys[0].key,
                        processing_key: selectedKeys.map(key => key.key),
                        escalation_manager_id: user.department_head_id,
                        escalation_director_id: user.category_director_id,
                        escalation_days: escalationResponse.data
                    };

                    console.log('Creating group with data:', groupData);

                    const groupResponse = await axios.post(
                        `${API_URL}/groups/groups`,
                        groupData,
                        {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    );
                    createdGroupId = groupResponse.data.id;
                }
            }

            console.log('Creating user...');
            const userData = {
                email: user.email.trim(),
                first_name: user.first_name.trim(),
                last_name: user.last_name.trim(),
                password: user.password,
                roles: [user.roles],
                category_keys: Array.isArray(user.category_keys) ? user.category_keys : []
            };

            console.log('Sending user data:', userData);

            const userResponse = await onCreateUser(userData);
            console.log('User created:', userResponse);

            if (createdGroupId && userResponse?.id) {
                const accessToken = localStorage.getItem('accessToken');
                const selectedKeys = categoryKeys.filter(key => user.category_keys.includes(key.id));

                const updateGroupData = {
                    name: selectedKeys[0].key,
                    processing_key: selectedKeys.map(key => key.key),
                    escalation_manager_id: user.department_head_id,
                    escalation_director_id: user.category_director_id,
                    user_ids: [userResponse.id]
                };

                await axios.put(
                    `${API_URL}/groups/groups/${createdGroupId}`,
                    updateGroupData,
                    {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
            }

        } catch (error) {
            console.error('Error in form submission:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
            }
            throw error;
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formGroup}>
                <label htmlFor="first_name">Имя</label>
                <input
                    id="first_name"
                    type="text"
                    name="first_name"
                    value={user.first_name}
                    onChange={onChange}
                    onBlur={handleBlur}
                    placeholder="Имя"
                    className={touched.first_name && errors.first_name ? styles.inputError : ''}
                />
                {touched.first_name && errors.first_name && (
                    <span className={styles.errorText}>{errors.first_name}</span>
                )}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="last_name">Фамилия</label>
                <input
                    id="last_name"
                    type="text"
                    name="last_name"
                    value={user.last_name}
                    onChange={onChange}
                    onBlur={handleBlur}
                    placeholder="Фамилия"
                    className={touched.last_name && errors.last_name ? styles.inputError : ''}
                />
                {touched.last_name && errors.last_name && (
                    <span className={styles.errorText}>{errors.last_name}</span>
                )}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="email">Email</label>
                <input
                    id="email"
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={onChange}
                    onBlur={handleBlur}
                    placeholder="Email"
                    className={touched.email && errors.email ? styles.inputError : ''}
                />
                {touched.email && errors.email && (
                    <span className={styles.errorText}>{errors.email}</span>
                )}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="password">Пароль</label>
                <input
                    id="password"
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={onChange}
                    onBlur={handleBlur}
                    placeholder="Пароль"
                    className={touched.password && errors.password ? styles.inputError : ''}
                />
                {touched.password && errors.password && (
                    <span className={styles.errorText}>{errors.password}</span>
                )}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="roles">Роль</label>
                <Select
                    id="roles"
                    name="roles"
                    options={roleOptions}
                    value={roleOptions.find(option => option.value === user.roles)}
                    onChange={handleRoleChange}
                    className={`${styles.selectRoles} ${touched.roles && errors.roles ? styles.selectError : ''}`}
                    classNamePrefix="select"
                    placeholder="Выберите роль"
                    styles={{
                        container: (baseStyles) => ({
                            ...baseStyles,
                            width: '100%',
                            cursor: "pointer"
                        }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#9B38DC' : '#ccc',
                            boxShadow: state.isFocused ? '0 0 0 1px #9B38DC' : 'none',
                            '&:hover': {
                                borderColor: '#9B38DC'
                            },
                            height: '38px',
                            width: '100%',
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: state.isSelected ? '#9B38DC' : state.isFocused ? 'rgba(155, 56, 220, 0.1)' : 'white',
                            color: state.isSelected ? 'white' : '#333',
                            '&:active': {
                                backgroundColor: '#7100b3',
                                color: 'white'
                            },
                            textAlign: 'left',
                        }),
                        menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: 'white',
                            width: '100%',
                        }),
                        valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            height: '38px',
                            justifyContent: 'flex-start',
                        }),
                        singleValue: (baseStyles) => ({
                            ...baseStyles,
                            textAlign: 'left',
                        }),
                    }}
                />
                {touched.roles && errors.roles && (
                    <span className={styles.errorText}>{errors.roles}</span>
                )}
            </div>
            {shouldShowField('category_key') && (
                <>
                    <div className={styles.formGroup}>
                        <label htmlFor="category_key">Категорийный ключ</label>
                        <Select
                            id="category_key"
                            name="category_key"
                            options={categoryKeyOptions}
                            value={[]}
                            onChange={handleCategoryKeyChange}
                            className={styles.selectRoles}
                            classNamePrefix="select"
                            placeholder="Выберите категорийные ключи"
                            isMulti={true}
                            styles={selectStyles}
                        />
                    </div>
                    {Array.isArray(user.category_keys) && user.category_keys.length > 0 && (
                        <div className={styles.selectedKeysContainer}>
                            <div className={styles.selectedKeys}>
                                {categoryKeyOptions
                                    .filter(option => user.category_keys.includes(option.value))
                                    .map(option => {
                                        const key = categoryKeys.find(k => k.id === option.value);
                                        return (
                                            <div key={option.value} className={styles.selectedKey}>
                                                <span className={styles.keyLabel}>{key?.key}</span>
                                                <button 
                                                    type="button"
                                                    className={styles.removeKeyButton}
                                                    onClick={() => handleRemoveKey(option.value)}
                                                >
                                                    ×
                                                </button>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    )}
                </>
            )}

            {shouldShowField('CATEGORY_HEAD') && (
                <div className={styles.formGroup}>
                    <label htmlFor="CATEGORY_HEAD">Руководитель товарного направления</label>
                    <Select
                        id="CATEGORY_HEAD"
                        name="CATEGORY_HEAD"
                        options={departmentHeads}
                        value={departmentHeads.find(option => option.value === user.department_head_id)}
                        onChange={handleDepartmentHeadChange}
                        className={styles.selectRoles}
                        classNamePrefix="select"
                        placeholder="Выберите руководителя"
                        styles={selectStyles}
                    />
                </div>
            )}

            {shouldShowField('category_director') && (
                <div className={styles.formGroup}>
                    <label htmlFor="category_director">Категорийный директор</label>
                    <Select
                        id="category_director"
                        name="category_director"
                        options={categoryDirectors}
                        value={categoryDirectors.find(option => option.value === user.category_director_id)}
                        onChange={handleCategoryDirectorChange}
                        className={styles.selectRoles}
                        classNamePrefix="select"
                        placeholder="Выберите директора"
                        styles={selectStyles}
                    />
                </div>
            )}

            <div className={styles.buttonGroup}>
                <button className={styles.save} type="submit">{submitButtonText}</button>
                <button className={styles.cancel} type="button" onClick={onCancel}>{cancelButtonText}</button>
            </div>
        </form>
    );
};

const selectStyles = {
    container: (baseStyles) => ({
        ...baseStyles,
        width: '100%',
        cursor: "pointer"
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? '#9B38DC' : '#ccc',
        boxShadow: state.isFocused ? '0 0 0 1px #9B38DC' : 'none',
        '&:hover': {
            borderColor: '#9B38DC'
        },
        height: '38px',
        width: '100%',
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state.isSelected ? '#9B38DC' : state.isFocused ? 'rgba(155, 56, 220, 0.1)' : 'white',
        color: state.isSelected ? 'white' : '#333',
        '&:active': {
            backgroundColor: '#7100b3',
            color: 'white'
        },
        textAlign: 'left',
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: 'white',
        width: '100%',
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        height: '38px',
        justifyContent: 'flex-start',
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        textAlign: 'left',
    }),
};

export default UserForm;
