import React from 'react';
// @ts-ignore
import styles from './Loader.module.css';

interface LoaderProps {
    text?: string
}

const Loader: React.FC = ({text}: LoaderProps) => (
    <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
    </div>
);

export default Loader;
