// Environment configuration
const ENV = process.env.REACT_APP_ENV || 'development';

interface EnvironmentConfig {
  API_URL: string;
  FRONT_URL: string;
}

const environments: Record<string, EnvironmentConfig> = {
  development: {
    API_URL: 'https://megamarket-adm-dev.bots.winsolutions.ru',
    FRONT_URL: 'https://megamarket-front-dev.bots.winsolutions.ru'
  },
  stage: {
    API_URL: 'https://megamarket-adm-stage.drawbot.winsolutions.ru',
    FRONT_URL: 'https://megamarket-front-stage.drawbot.winsolutions.ru'
  }
};

export const config = environments[ENV] || environments.development; 