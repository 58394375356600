import React, { useState, useEffect } from 'react';
import UserForm from './UserForm';
import styles from './CreateUserModal.module.css';

const CreateUserModal = ({ onClose, onCreateUser }) => {
    const [newUser, setNewUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        roles: '',
        category_keys: [],
        escalation_director_id: null
    });

    const roles = {
        ADMIN: "Администратор",
        ANALYST: "Аналитик департамента коммерческой аналитики",
        CATEGORY_DIRECTOR: "Категорийный директор",
        CATEGORY_HEAD: "Руководитель товарного направления",
        SYSTEM:'Ответственный за категории'
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    const handleRoleChange = (selectedOption) => {
        setNewUser({ ...newUser, roles: selectedOption.value });
    };

    const handleSubmit = async (userData) => {
        try {
            const response = await onCreateUser(userData);
            
            if (!response) {
                throw new Error('No response from API');
            }

            onClose();
            return response;
        } catch (error) {
            console.error('CreateUserModal: Error:', error);
            throw error;
        }
    };

    useEffect(() => {
        document.body.classList.add('modal-open');
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, []);

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>Создать нового пользователя</h2>
                <UserForm
                    user={newUser}
                    onChange={handleChange}
                    onRoleChange={handleRoleChange}
                    onCreateUser={handleSubmit}
                    submitButtonText="Создать"
                    roles={roles}
                    cancelButtonText="Отменить"
                    onCancel={onClose}
                />
            </div>
        </div>
    );
};

export default CreateUserModal;
