import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verify2FA, logout } from '../../features/auth/authSlice';
import { AppDispatch, RootState } from '../../app/store';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';

// @ts-ignore
import styles from '../auth/Auth.module.css';
import ErrorModal from "../ErrorModal";

const TwoFactorAuth = () => {
  const [token, setToken] = useState('');
  const [fetchErr, setFetchErr] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { userId, qrCodeUri, twoFactorSecret, error } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (userId) {
      try {
        const resultAction = await dispatch(verify2FA({ user_id: userId, token }));
        if (verify2FA.fulfilled.match(resultAction)) {
          if (window.location.search.includes('registration=true')) {
            await dispatch(logout());
            setRegistrationSuccess(true);
            setTimeout(() => {
              navigate('/login');
            }, 5000);
          }
        } else if (verify2FA.rejected.match(resultAction)) {
          setFetchErr(getErrorMessage(resultAction.error));
        }
      } catch (error) {
        setFetchErr('Ошибка при отправке запроса 2FA');
        console.error('Ошибка при отправке запроса 2FA:', error);
      }
    }
  };

  useEffect(() => {
    if (error) {
      setFetchErr(getErrorMessage(error));
    }
  }, [error]);

  const getErrorMessage = (error: any): string => {
    if (typeof error === 'string') return error;
    if (error && typeof error === 'object') {
      if ('message' in error) return error.message;
      if ('detail' in error) return error.detail;
      return JSON.stringify(error);
    }
    return 'Произошла неизвестная ошибка';
  };

  if (registrationSuccess) {
    return (
      <div className={styles.twoFactor}>
        <h2>Регистрация успешно завершена!</h2>
        <p className={styles.text}>
          Ожидайте получения роли. На ваш email придет уведомление.
          <br />
          Вы будете перенаправлены на страницу входа через несколько секунд.
        </p>
      </div>
    );
  }

  return (
    <div className={styles.twoFactor}>
      {fetchErr && <ErrorModal message={"Неправильный код двухфакторной аутентификации"} onClose={() => setFetchErr(null)} />}
      <h2 className={styles.qr_block}>Двухфакторная аутентификация</h2>
      {qrCodeUri && (
        <div>
          <p className={styles.text}>Отсканируйте этот QR-код с помощью <br/> приложения аутентификатора:</p>
          <QRCode value={qrCodeUri} size={100} />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Введите код 2FA"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          className={styles.input}
        />
        <button type="submit" className={styles.button}>Подтвердить</button>
      </form>
    </div>
  );
};

export default TwoFactorAuth;
