import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { AuthService, LoginResponse } from '../../services/authService';
import { config } from '../../config/environment';

export const API_URL = config.API_URL;

interface UserData {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  roles: string[];
  category_keys: string[];
}

interface VerificationData {
  user_id: number;
  token: string;
}

interface VerificationResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  message: string;
  user_id: number;
}

interface TokenResponse {
  access_token: string;
  refresh_token: string;
}

export const isTokenValid = (token: string | null): boolean => {
  if (!token) return false;
  try {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  } catch (error) {
    return false;
  }
};


const saveTokensToStorage = (accessToken: string, refreshToken: string) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};


const removeTokensFromStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userRole');
  localStorage.removeItem('userId');
};

interface RegisterData {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  roles: string[];
  category_keys: string[];
}

export const register = createAsyncThunk<any, UserData>(
  'auth/register',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/auth/register`, userData);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue('An unexpected error occurred');
    }
  }
);

export const login = createAsyncThunk<LoginResponse, LoginCredentials>(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await AuthService.login(credentials);
      
      if (response.user_id) {
        localStorage.setItem('userId', response.user_id.toString());
        
        if (response.roles && response.roles.length > 0) {
          localStorage.setItem('userRole', response.roles[0]);
        }
      }
      
      return response;
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue(
          error.response.data.detail || 
          error.response.data.message || 
          'Неверный email или пароль'
        );
      }
      return rejectWithValue('Произошла ошибка при входе');
    }
  }
);

export const verify2FA = createAsyncThunk<VerificationResponse, VerificationData>(
  'auth/verify2FA',
  async (verificationData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/auth/verify-2fa`, verificationData);
      saveTokensToStorage(response.data.access_token, response.data.refresh_token);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue('An unexpected error occurred');
    }
  }
);

export const refreshToken = createAsyncThunk<TokenResponse, string>(
  'auth/refreshToken',
  async (refreshToken, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/auth/refresh`, { refresh_token: refreshToken }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      });
      if (response.status === 200) {
        saveTokensToStorage(response.data.access_token, response.data.refresh_token);
        return response.data;
      } else {
        removeTokensFromStorage();
        return rejectWithValue('Token refresh failed');
      }
    } catch (error) {
      removeTokensFromStorage();
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue('An unexpected error occurred');
    }
  }
);

export interface LoginCredentials {
  email: string;
  password: string;
}

interface AuthState {
  user: any | null;
  userId: number | null;
  require2FA: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  twoFactorSecret: string | null;
  qrCodeUri: string | null;
  isLoading: boolean;
  error: string | null;
  tokenType: string | null;
  isAuthenticated: boolean;
  showErrorModal: boolean;
}

const initialState: AuthState = {
  user: null,
  userId: null,
  require2FA: false,
  accessToken: null,
  refreshToken: null,
  twoFactorSecret: null,
  qrCodeUri: null,
  isLoading: false,
  error: null,
  tokenType: null,
  isAuthenticated: false,
  showErrorModal: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.userId = null;
      state.require2FA = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.twoFactorSecret = null;
      state.qrCodeUri = null;
      state.error = null;
      state.tokenType = null;
      state.isAuthenticated = false;
      removeTokensFromStorage();
    },
    clearError: (state) => {
      state.error = null;
    },
    setTokens: (state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isLoading = false;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setShowErrorModal: (state, action: PayloadAction<boolean>) => {
      state.showErrorModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.user = action.payload;
        state.userId = action.payload.id;
        state.twoFactorSecret = action.payload.two_factor_secret;
        state.qrCodeUri = action.payload.qr_code_uri;
        state.require2FA = true;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<LoginResponse>) => {
        state.isLoading = false;
        state.userId = action.payload.user_id;
        state.require2FA = action.payload.require_2fa;
        if (!action.payload.require_2fa) {
          state.isAuthenticated = true;
          state.user = {
            id: action.payload.user_id,
            role: action.payload.roles[0]
          };
        } else {
          state.isAuthenticated = false;
          state.user = null;
        }
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        state.showErrorModal = true;
      })
      .addCase(verify2FA.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verify2FA.fulfilled, (state, action: PayloadAction<VerificationResponse>) => {
        state.isLoading = false;
        state.accessToken = action.payload.access_token;
        state.refreshToken = action.payload.refresh_token;
        state.tokenType = action.payload.token_type;
        state.userId = action.payload.user_id;
        state.require2FA = false;
        state.twoFactorSecret = null;
        state.qrCodeUri = null;
        state.isAuthenticated = true;
        saveTokensToStorage(action.payload.access_token, action.payload.refresh_token);
      })
      .addCase(verify2FA.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(refreshToken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(refreshToken.fulfilled, (state, action: PayloadAction<TokenResponse>) => {
        state.isLoading = false;
        state.accessToken = action.payload.access_token;
        state.refreshToken = action.payload.refresh_token;
        saveTokensToStorage(action.payload.access_token, action.payload.refresh_token);
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        state.user = null;
        state.userId = null;
        state.require2FA = false;
        state.accessToken = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
        removeTokensFromStorage();
      });
  },
});

export const { logout, clearError, setShowErrorModal } = authSlice.actions;
export default authSlice.reducer;
