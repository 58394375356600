import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// @ts-ignore
import styles from './Navbar.module.css';

const Navbar: React.FC = () => {
    const userRole = localStorage.getItem('userRole');
    const [activeItem, setActiveItem] = useState('');
    const location = useLocation();

    useEffect(() => {
        setActiveItem(location.pathname);
    }, [location]);

    return (
        <nav>
            <ul className={styles.nav}>
                {userRole === 'ADMIN' && (
                    <li>
                        <Link 
                            to="/applications" 
                            className={activeItem === '/applications' ? styles.active : ''}
                        >
                            Пользователи
                        </Link>
                    </li>
                )}
                {(userRole === 'ADMIN' || userRole === "ANALYST") && (
                    <li>
                        <Link 
                            to="/ligaments" 
                            className={activeItem === '/ligaments'  ? styles.active : ''}
                        >
                            Связки
                        </Link>
                    </li>
                )}
                <li>
                    <Link 
                        to="/directories" 
                        className={activeItem === '/directories'|| activeItem !== '/ligaments' && activeItem !== '/applications'  ? styles.active : ''}
                    >
                        Данные
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
