import React, {useState, useRef} from 'react';
// @ts-ignore
import styles from './Directories.module.css';
import axios from 'axios';
import {API_URL} from "../../features/auth/authSlice";
import ErrorModal from "../../components/ErrorModal";
import MasageModal from '../../components/MasageModal';

async function executeRequestWithTokenRefresh(requestFunction: () => Promise<any>) {
  try {
    return await requestFunction();
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${API_URL}/auth/refresh`, { refresh_token: refreshToken });
        localStorage.setItem('accessToken', response.data.access_token);
        return await requestFunction();
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        throw refreshError;
      }
    }
    throw error;
  }
}

const Directories: React.FC = () => {
  const userRole = localStorage.getItem('userRole')
  const [errMasage, setErrMasage] = useState('');
  const [masage, setMasage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleProcessCompletion = async () => {
    setIsLoading(true);
    setLoadingMessage('формирование итогового файла...');
    try {
      await executeRequestWithTokenRefresh(async () => {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(API_URL + '/excel/process_report', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setMasage('Формирование итогового файла успешно запущено');
      });
    } catch (error) {
      console.error('Ошибка при формирование процесса:', error);
      setErrMasage( 'Менеджеры еще не отправили отчет!');
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const handleDownloadReport = async () => {
    setIsLoading(true);
    setLoadingMessage('Скачивание отчета...');
    try {
      await executeRequestWithTokenRefresh(async () => {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(API_URL + '/excel/download', {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const filename = response.headers['content-disposition']?.split('filename=')[1]?.trim().replace(/^"|"$/g, '') || 'report.xlsx';
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    } catch (error) {
      console.error('Ошибка при скачивании отчета:', error);
      setErrMasage( 'У Вас нет доступа к отчету'); //err
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const handleDownloadTemplate = async () => {
    setIsLoading(true);
    setLoadingMessage('Скачивание шаблона...');
    try {
      await executeRequestWithTokenRefresh(async () => {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${API_URL}/excel/download/template`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'
        });
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const filename = response.headers['content-disposition']?.split('filename=')[1]?.trim().replace(/^"|"$/g, '') || 'template.xlsx';
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    } catch (error) {
      console.error('Ошибка при скачивании шаблона:', error);
      setErrMasage('Произошла ошибка при скачивании шаблона отчета');
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      setErrMasage('Файл не выбран');
      return;
    }

    setIsLoading(true);
    setLoadingMessage('Загрузка файла...');
    const formData = new FormData();
    formData.append('file', file);

    try {
      await executeRequestWithTokenRefresh(async () => {
        const token = localStorage.getItem('accessToken');
        let url;

        if (userRole === "ADMIN" || userRole === "ANALYST") {
          url = `${API_URL}/excel/upload/initial`;
        } else {
          url = `${API_URL}/excel/upload`;
        }

        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formData,
        };

        try {
          const response = await fetch(url, requestOptions);
          
          if (!response.ok) {
            if (response.status === 422) {
              const blob = await response.blob();
              const downloadUrl = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = downloadUrl;
              const contentDisposition = response.headers.get('content-disposition');
              const filename = contentDisposition?.split('filename=')[1]?.trim().replace(/^"|"$/g, '') || 'errors.xlsx';
              link.download = filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(downloadUrl);
              setErrMasage('Обнаружены ошибки в файле. Скачивание отчета об ошибках начато.');
            } else {
              throw new Error('Upload failed');
            }
          } else {
            setMasage('Файл успешно загружен');
          }
        } catch (error) {
          throw error;
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      });
    } catch (error: any) {
      console.error('Ошибка при загрузке файла:', error);
      setErrMasage('Произошла ошибка при загрузке файла');
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  return (
    <div className={styles.body}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        accept=".xlsx,.xls"
      />

      {userRole === "ADMIN" || userRole === "ANALYST" ?(
        <>
          <button onClick={handleDownloadTemplate} disabled={isLoading}>Скачать шаблон</button>
          <button onClick={triggerFileInput} disabled={isLoading}>Загрузить файл</button>
          <button onClick={handleProcessCompletion} disabled={isLoading}>Итоговый файл</button>
        </>
      ):
        <>
          <button onClick={triggerFileInput} disabled={isLoading}>Загрузить отчет</button>
          <button onClick={handleDownloadReport} disabled={isLoading}>Скачать отчет</button>
        </>
      }

      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingSpinner}></div>
          <h3>{loadingMessage}</h3>
          <p>Пожалуйста, не закрывайте и не обновляйте страницу</p>
        </div>
      )}

      {errMasage && <ErrorModal message={errMasage} onClose={() => {setErrMasage('')}} />}
      {masage && <MasageModal message={masage} onClose={() => {setMasage('')}} />}
    </div>
  )
};

export default Directories;
