import React from 'react';
// @ts-ignore
import styles from './ErrorModal.module.css';

interface MasageModalProps {
    message: string;
    onClose: () => void;
}

const MasageModal: React.FC<MasageModalProps> = ({ message, onClose }) => {
    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>Успех</h2>
                <p>{message}</p>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <button className='MasageModal' type='button' onClick={onClose}>Закрыть</button>
                </div>

            </div>
        </div>
    );
};

export default MasageModal;
