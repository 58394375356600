import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { register, logout } from '../../features/auth/authSlice';
import { AppDispatch } from '../../app/store';
// @ts-ignore
import styles from '../auth/Auth.module.css';
import { useNavigate } from 'react-router-dom';

interface RegisterProps {
  onSwitchToLogin: () => void;
}

interface FormErrors {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
}

const Register: React.FC<RegisterProps> = ({ onSwitchToLogin }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState<string[]>([]);
  const [categoryKeys, setCategoryKeys] = useState(['']);
  const [errors, setErrors] = useState<FormErrors>({});
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [timeLeft, setTimeLeft] = useState(20);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (registrationSuccess && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setRegistrationSuccess(false);
      onSwitchToLogin();
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [registrationSuccess, timeLeft, onSwitchToLogin]);

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    if (!email) newErrors.email = 'Электронная почта обязательна';
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Неверный формат электронной почты';
    if (!firstName) newErrors.firstName = 'Имя обязательно';
    if (!lastName) newErrors.lastName = 'Фамилия обязательна';
    if (!password) newErrors.password = 'Пароль обязателен';
    else if (password.length < 6) newErrors.password = 'Пароль должен быть не менее 6 символов';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const resultAction = await dispatch(register({ 
          email, 
          first_name: firstName, 
          last_name: lastName, 
          password, 
          roles, 
          category_keys: categoryKeys.filter(key => key.trim() !== '')
        }));
        
        if (register.fulfilled.match(resultAction)) {
          await dispatch(logout());
          navigate('/registration-success', { replace: true, state: { fromRegistration: true } });
        }
      } catch (error) {
        console.error('Registration error:', error);
      }
    }
  };

  return (
    <div className={styles.block}>
      <div className={styles.authContainer}>
        <h2>Регистрация</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <input
              type="email"
              placeholder="Электронная почта"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`${styles.input} ${errors.email ? styles.inputError : ''}`}
            />
            {errors.email && <p className={styles.errorText}>{errors.email}</p>}
          </div>
          <div className={styles.inputGroup}>
            <input
              type="text"
              placeholder="Имя"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={`${styles.input} ${errors.firstName ? styles.inputError : ''}`}
            />
            {errors.firstName && <p className={styles.errorText}>{errors.firstName}</p>}
          </div>
          <div className={styles.inputGroup}>
            <input
              type="text"
              placeholder="Фамилия"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={`${styles.input} ${errors.lastName ? styles.inputError : ''}`}
            />
            {errors.lastName && <p className={styles.errorText}>{errors.lastName}</p>}
          </div>
          <div className={styles.inputGroup}>
            <input
              type="password"
              placeholder="Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`${styles.input} ${errors.password ? styles.inputError : ''}`}
            />
            {errors.password && <p className={styles.errorText}>{errors.password}</p>}
          </div>
          <button type="submit" className={styles.button}>Зарегистрироваться</button>
        </form>
        <p className={styles.switchText}>
          <button onClick={onSwitchToLogin} className={styles.switchButton}>
            Войти
          </button>
        </p>
      </div>
    </div>
  );
};

export default Register;
