import React, { useEffect, useState, useRef } from 'react';
import Loader from '../components/Loader';
import useLoader from '../hooks/useLoader';
// @ts-ignore
import styles from './directories/Directories.module.css';
import { Link } from "react-router-dom";
import axios from 'axios';
import {API_URL} from "../features/auth/authSlice";
// @ts-ignore
import loop from '../icons/loop.png'
// @ts-ignore
import vector from '../icons/Vector.png'
import Select from 'react-select';

interface User {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    is_active: boolean;
    roles: string[];
    category_keys?: number[];
}

interface Group {
    id: number;
    name: string;
    processing_key: string[] | string;
    escalation_manager: {
        id: number,
        first_name: string,
        last_name: string,
    };
    escalation_director: {
        id: number,
        first_name: string,
        last_name: string,
    } | null;
    escalation_days: number;
    users: User[];
    category_director_id: number | null;
}

interface NewGroup {
    name: string;
    processing_key: (string | number)[];
    escalation_manager_id: number | null;
    users: User[];
    category_director_id: number | null;
    product_manager_id: number | null;
}

interface CategoryKey {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    is_active: boolean;
    roles: string[];
    category_keys: number[];
    two_factor_secret: string;
}

interface CategoryKeyValue {
    id: number;
    key: string;
}

interface SelectOption {
    value: number;
    label: string;
}

interface EscalationDaysResponse {
    escalation_days: number;
}

async function executeRequestWithTokenRefresh(requestFunction: () => Promise<any>) {
    try {
        return await requestFunction();
    } catch (error: any) {
        if (error.response && error.response.status === 401) {
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await axios.post(`${API_URL}/auth/refresh`, { refresh_token: refreshToken });
                localStorage.setItem('accessToken', response.data.access_token);
                return await requestFunction();
            } catch (refreshError) {
                console.error('Ошибка обновления токена:', refreshError);
                throw refreshError;
            }
        }
        throw error;
    }
}


const formatProcessingKey = (key: string | string[] | number[] | (string | number)[], categoryKeyValues: CategoryKeyValue[]): string => {
    if (Array.isArray(key)) {
        if (key.every(item => typeof item === 'string' && item.length === 1)) {
            return key.join('').replace(/[{"}]/g, '').split(',').map(item => item.trim()).join(', ');
        }
        return key.map(item => {
            if (typeof item === 'number') {
                const keyObj = categoryKeyValues.find((kv: CategoryKeyValue) => kv.id === item);
                return keyObj ? keyObj.key : '';
            }
            return item;
        }).filter(Boolean).join(', ');
    }
    return key;
};

const Ligaments: React.FC = () => {
    const pages = ['Группы', 'Категорийный ключ-пользователь']
    const [page, setPage] = React.useState(0);
    const [groups, setGroups] = useState<Group[]>([]);
    const [newGroup, setNewGroup] = useState<NewGroup>({
        name: '',
        processing_key: [],
        escalation_manager_id: null,
        // escalation_days: 0,
        users: [],
        category_director_id: null,
        product_manager_id: null,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [categoryKeys, setCategoryKeys] = useState<CategoryKey[]>([]);
    const [editingCategoryKey, setEditingCategoryKey] = useState<CategoryKey | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [categoryKeyValues, setCategoryKeyValues] = useState<CategoryKeyValue[]>([]);
    const [showKeysPopup, setShowKeysPopup] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryKeySearchTerm, setCategoryKeySearchTerm] = useState('');
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [newKey, setNewKey] = useState<string>('');
    const popupRef = useRef<HTMLDivElement>(null);
    const [showUsersPopup, setShowUsersPopup] = useState<number | null>(null);
    const [groupUsers, setGroupUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [currentGroupId, setCurrentGroupId] = useState<number | null>(null);
    const [editingGroup, setEditingGroup] = useState<Group | null>(null);
    const [activePopup, setActivePopup] = useState<string | null>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const { isLoading, showLoader, hideLoader } = useLoader();
    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [admins, setAdmins] = useState<User[]>([]);
    const [categoryKeyOptions, setCategoryKeyOptions] = useState<SelectOption[]>([]);
    const [modalMode, setModalMode] = useState<'create' | 'edit'>('create');

    useEffect(() => {
        if (pages[page] === 'Группы') {
            fetchGroups();
            fetchCategoryKeys();
        } else if (pages[page] === 'Категорийный ключ-пользователь') {
            fetchCategoryKeys();
        }
    }, [page]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setShowKeysPopup(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                closeAllPopups();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        fetchAllUsers();
    }, []);

    useEffect(() => {
        if (isModalOpen && !editingGroup && modalMode === 'create') {
            fetchDefaultEscalationDays().then(days => {
                setNewGroup(prev => ({
                    ...prev,
                    escalation_days: days
                }));
            });
        }
    }, [isModalOpen, editingGroup, modalMode]);

    const fetchGroups = async () => {
        showLoader();
        try {
            await executeRequestWithTokenRefresh(async () => {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    throw new Error('Токен доступа не найден');
                }

                const response = await axios.get<Group[]>(`${API_URL}/groups/groups`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setGroups(response.data);
            });
        } catch (error) {
            console.error('Ошибка при получении групп:', error);
        } finally {
            hideLoader();
        }
    };

    const fetchCategoryKeys = async () => {
        showLoader();
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                console.error('Access token not found');
                return;
            }

            const [usersResponse, keyValuesResponse] = await Promise.all([
                axios.get<CategoryKey[]>(`${API_URL}/users/users`, {
                    headers: { 'Authorization': `Bearer ${accessToken}` }
                }),
                axios.get<CategoryKeyValue[]>(`${API_URL}/users/category_keys`, {
                    headers: { 'Authorization': `Bearer ${accessToken}` }
                })
            ]);

            setCategoryKeys(usersResponse.data);
            setCategoryKeyValues(keyValuesResponse.data);

            const options = keyValuesResponse.data.map(key => ({
                value: key.id,
                label: key.key
            }));
            setCategoryKeyOptions(options);
        } catch (error) {
            console.error('Error fetching category keys:', error);
            setError('Failed to fetch category keys. Please try again.');
        } finally {
            hideLoader();
        }
    };

    const getMatchingKeys = (keyIds: number[] | undefined) => {
        if (!keyIds) return '';
        return keyIds
            .map(id => categoryKeyValues.find(kv => kv.id === id)?.key)
            .filter(Boolean)
            .join(', ');
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | { name: string; value: any }) => {
        const { name, value } = 'target' in e ? e.target : e;
        setNewGroup(prev => ({
            ...prev,
            [name]: name === 'escalation_days' ? parseInt(value) : value
        }));
    };

    const createGroup = async (e: React.FormEvent) => {
        e.preventDefault();
        showLoader();
        try {
            await executeRequestWithTokenRefresh(async () => {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    throw new Error('Access token not found');
                }

                const processingKeys = newGroup.processing_key
                    .map(keyId => {
                        const keyObj = categoryKeyValues.find(kv => kv.id === keyId);
                        return keyObj ? keyObj.key : null;
                    })
                    .filter(key => key !== null) as string[];

                const groupData = {
                    name: newGroup.name,
                    processing_key: processingKeys,
                    escalation_manager_id: newGroup.product_manager_id,
                    escalation_director_id: newGroup.category_director_id,
                    // escalation_days: newGroup.escalation_days,
                    user_ids: newGroup.users.map(u => u.id)
                };

                const response = await axios.post<Group>(
                    `${API_URL}/groups/groups`,
                    groupData,
                    {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                setGroups(prev => [...prev, response.data]);
                setIsModalOpen(false);
                clearGroupStates();
            });
        } catch (error) {
            console.error('Error creating group:', error);
        } finally {
            hideLoader();
        }
    };

    const handleEditCategoryKey = (key: CategoryKey) => {
        openPopup('editCategoryKey');
        setEditingCategoryKey(key);
        setIsEditModalOpen(true);
    };

    const handleCategoryKeyChange = (userId: number, selectedKeys: string[]) => {
        const newKeyIds = selectedKeys.map(key => {
            const foundKey = categoryKeyValues.find(kv => kv.key === key);
            return foundKey ? foundKey.id : -1;
        }).filter(id => id !== -1);

        setCategoryKeys(prevKeys =>
            prevKeys.map(key =>
                key.id === userId ? { ...key, category_keys: newKeyIds } : key
            )
        );
    };

    const handleCategoryKeyInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedKeys(selectedOptions);
        if (editingCategoryKey) {
            const newKeyIds = selectedOptions.map(key => {
                const foundKey = categoryKeyValues.find(kv => kv.key === key);
                return foundKey ? foundKey.id : -1;
            }).filter(id => id !== -1);

            setEditingCategoryKey({ ...editingCategoryKey, category_keys: newKeyIds });
        }
    };

    const handleAddNewKey = () => {
        if (newKey && editingCategoryKey) {
            const foundKey = categoryKeyValues.find(kv => kv.key === newKey);
            if (foundKey) {
                const updatedKeys = [...editingCategoryKey.category_keys, foundKey.id];
                setEditingCategoryKey({ ...editingCategoryKey, category_keys: updatedKeys });
                setSelectedKeys([...selectedKeys, newKey]);
            }
            setNewKey('');
        }
    };

    const handleRemoveKey = (event: React.MouseEvent, keyId: number) => {
        event.stopPropagation();
        if (editingCategoryKey) {
            const updatedKeys = editingCategoryKey.category_keys.filter(id => id !== keyId);
            setEditingCategoryKey({ ...editingCategoryKey, category_keys: updatedKeys });
        }
    };

    const updateCategoryKey = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!editingCategoryKey) return;
        showLoader();
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                console.error('Access token not found');
                return;
            }

            await axios.put(
                `${API_URL}/users/users/${editingCategoryKey.id}/category-keys`,
                { category_keys: editingCategoryKey.category_keys },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            setCategoryKeys(prevKeys =>
                prevKeys.map(key =>
                    key.id === editingCategoryKey.id ? editingCategoryKey : key
                )
            );

            setIsEditModalOpen(false);
            setEditingCategoryKey(null);
            setError(null);
            fetchCategoryKeys();
        } catch (error) {
            console.error('Error updating category key:', error);
            setError('Failed to update category key. Please try again.');
        } finally {
            hideLoader();
        }
    };

    const updateCategoryKeyById = async (userId: number) => {
        const userToUpdate = categoryKeys.find(key => key.id === userId);
        if (!userToUpdate) return;
        showLoader();
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                console.error('Access token not found');
                return;
            }

            await axios.put(
                `${API_URL}/users/users/${userId}/category-keys`,
                { category_keys: userToUpdate.category_keys },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            setError(null);
            fetchCategoryKeys();
        } catch (error) {
            console.error('Error updating category key:', error);
            setError('Failed to update category key. Please try again.');
        } finally {
            hideLoader();
        }
    };

    const handleShowKeys = (userId: number) => {
        if (activePopup === `keys-${userId}`) {
            closeAllPopups();
        } else {
            openPopup(`keys-${userId}`);
            setShowKeysPopup(userId);
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleCategoryKeySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCategoryKeySearchTerm(e.target.value);
    };

    const handleAddOrRemoveKey = (keyId: number, keyValue: string) => {
        if (editingCategoryKey) {
            const updatedKeys = editingCategoryKey.category_keys.includes(keyId)
                ? editingCategoryKey.category_keys.filter(id => id !== keyId)
                : [...editingCategoryKey.category_keys, keyId];

            setEditingCategoryKey({ ...editingCategoryKey, category_keys: updatedKeys });
        }
    };

    const filteredCategoryKeyValues = categoryKeyValues
        .filter(kv => kv.key.toLowerCase().includes(categoryKeySearchTerm.toLowerCase()))
        .sort((a, b) => {
            const aStartsWith = a.key.toLowerCase().startsWith(categoryKeySearchTerm.toLowerCase());
            const bStartsWith = b.key.toLowerCase().startsWith(categoryKeySearchTerm.toLowerCase());
            if (aStartsWith && !bStartsWith) return -1;
            if (!aStartsWith && bStartsWith) return 1;
            return a.key.localeCompare(b.key);
        });

    const filteredCategoryKeys = categoryKeys.filter(key =>
        key.is_active &&
        (key.id.toString().includes(searchTerm) ||
        key.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        key.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getMatchingKeys(key.category_keys).toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleShowUsers = async (groupId: number) => {
        if (activePopup === `users-${groupId}`) {
            closeAllPopups();
        } else {
            openPopup(`users-${groupId}`);
            showLoader();
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    console.error('Access token not found');
                    return;
                }

                const response = await axios.get<Group[]>(`${API_URL}/groups/groups`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                const group = response.data.find(g => g.id === groupId);
                if (!group) {
                    console.error('Group not found');
                    return;
                }

                const usersResponse = await axios.get<User[]>(`${API_URL}/users/users`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                setGroupUsers(usersResponse.data);

                setSelectedUsers(group.users.map(u => u.id));
                setShowUsersPopup(groupId);
                setCurrentGroupId(groupId);
            } catch (error) {
                console.error('Error fetching groups or users:', error);
            } finally {
                hideLoader();
            }
        }
    };

    const handleUserSelection = (userId: number) => {
        if (editingGroup) {
            const selectedUser = groupUsers.find(u => u.id === userId);
            if (selectedUser) {
                if (editingGroup.users.some(u => u.id === userId)) {

                    const updatedUsers = editingGroup.users.filter(u => u.id !== userId);

                    const remainingUserKeys = updatedUsers.flatMap(u => u.category_keys || []);

                    const updatedProcessingKey = Array.from(new Set(remainingUserKeys))
                        .map(keyId => {
                            const keyObj = categoryKeyValues.find(kv => kv.id === keyId);
                            return keyObj ? keyObj.key : null;
                        })
                        .filter((key): key is string => key !== null);

                    setEditingGroup({
                        ...editingGroup,
                        users: updatedUsers,
                        processing_key: updatedProcessingKey
                    });
                } else {
                    const updatedUsers = [...editingGroup.users, selectedUser];

                    const allUserKeys = updatedUsers.flatMap(u => u.category_keys || []);

                    const updatedProcessingKey = Array.from(new Set(allUserKeys))
                        .map(keyId => {
                            const keyObj = categoryKeyValues.find(kv => kv.id === keyId);
                            return keyObj ? keyObj.key : null;
                        })
                        .filter((key): key is string => key !== null);

                    setEditingGroup({
                        ...editingGroup,
                        users: updatedUsers,
                        processing_key: updatedProcessingKey
                    });
                }
            }
        } else {
            setSelectedUsers(prev =>
                prev.includes(userId)
                    ? prev.filter(id => id !== userId)
                    : [...prev, userId]
            );
        }
    };

    const handleSaveUsers = async () => {
        if (!currentGroupId) return;
        showLoader();
        try {
            await executeRequestWithTokenRefresh(async () => {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    throw new Error('Access token not found');
                }

                const selectedGroupUsers = groupUsers.filter(user => selectedUsers.includes(user.id));
                const processingKeys = Array.from(new Set(
                    selectedGroupUsers.flatMap(user => user.category_keys || [])
                        .map(keyId => {
                            const keyObj = categoryKeyValues.find(kv => kv.id === keyId);
                            return keyObj ? keyObj.key : null;
                        })
                        .filter((key): key is string => key !== null)
                ));

                try {
                    const response = await axios.put(
                        `${API_URL}/groups/groups/${currentGroupId}`,
                        { 
                            user_ids: selectedUsers,
                            processing_key: processingKeys
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    );

                    setGroups(prev => prev.map(group => group.id === currentGroupId ? response.data : group));
                    setShowUsersPopup(null);
                    setGroupUsers([]);
                    setSelectedUsers([]);
                    setCurrentGroupId(null);
                } catch (error: any) {
                    if (error.response && error.response.status === 403) {
                        setError('У вас недостаточно прав для выполнения этого действия');
                    } else {
                        throw error;
                    }
                }
            });
        } catch (error) {
            console.error('Error updating group users:', error);
        } finally {
            hideLoader();
        }
    };

    const handleEditGroup = (group: Group) => {
        setModalMode('edit');
        
        const userCategoryKeyIds = group.users.flatMap(user => user.category_keys || []);
        
        setEditingGroup({
            ...group,
            category_director_id: group.escalation_director?.id || null,
        });
        
        setNewGroup({
            name: group.name,
            processing_key: userCategoryKeyIds,
            escalation_manager_id: group.escalation_manager?.id || null,
            // escalation_days: group.escalation_days,
            users: group.users,
            category_director_id: group.escalation_director?.id || null,
            product_manager_id: group.escalation_manager?.id || null,
        });
        setIsModalOpen(true);
    };

    const handleUpdateGroup = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!editingGroup) return;
        showLoader();
        try {
            await executeRequestWithTokenRefresh(async () => {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    throw new Error('Access token not found');
                }
                const processingKeys = editingGroup.users
                    .flatMap(user => user.category_keys || [])
                    .map(keyId => {
                        const keyObj = categoryKeyValues.find(kv => kv.id === keyId);
                        return keyObj ? keyObj.key : null;
                    })
                    .filter((key): key is string => key !== null);

                const groupData = {
                    name: editingGroup.name,
                    processing_key: processingKeys,
                    escalation_manager_id: editingGroup.escalation_manager.id,
                    escalation_director_id: editingGroup.category_director_id,
                    escalation_days: editingGroup.escalation_days,
                    user_ids: editingGroup.users.map(u => u.id)
                };

                try {
                    const response = await axios.put<Group>(
                        `${API_URL}/groups/groups/${editingGroup.id}`,
                        groupData,
                        {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    );

                    setGroups(prev => prev.map(group => group.id === editingGroup.id ? response.data : group));
                    setIsModalOpen(false);
                    clearGroupStates();
                } catch (error: any) {
                    if (error.response && error.response.status === 403) {
                        setError('У вас недостаточно прав для выполнения этого действия');
                    } else {
                        throw error;
                    }
                }
            });
        } catch (error) {
            console.error('Error updating group:', error);
        } finally {
            hideLoader();
        }
    };

    const handleDeleteGroup = () => {
        setShowDeleteConfirmation(true);
    };

    const confirmDeleteGroup = async () => {
        if (!editingGroup) return;
        showLoader();
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                console.error('Access token not found');
                return;
            }

            await axios.delete(
                `${API_URL}/groups/groups/${editingGroup.id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );

            setGroups(prev => prev.filter(group => group.id !== editingGroup.id));
            setIsModalOpen(false);
            setEditingGroup(null);
            setShowDeleteConfirmation(false);
        } catch (error) {
            console.error('Error deleting group:', error);
        } finally {
            hideLoader();
        }
    };

    const cancelDeleteGroup = () => {
        setShowDeleteConfirmation(false);
    };

    const closeAllPopups = () => {
        setActivePopup(null);
        setIsModalOpen(false);
        setIsEditModalOpen(false);
        setShowKeysPopup(null);
        setShowUsersPopup(null);
        setEditingCategoryKey(null);
        setEditingGroup(null);
        setCategoryKeySearchTerm('');
    };

    const openPopup = (popupName: string) => {
        closeAllPopups();
        setActivePopup(popupName);
    };

    const keyItemStyles = {
        keyItem: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px',
            borderBottom: '1px solid #eee',
        },
        keyItemContent: {
            flexGrow: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        addRemoveKeyButton: {
            flexShrink: 0,
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            borderRadius: '50%',
            cursor: 'pointer',
            marginLeft: '10px',
            color: 'white',
            fontWeight: 'bold',
            transition: 'background-color 0.3s ease',
        },
        addButton: {
            backgroundColor: '#00c853',
        },
        removeButton: {
            backgroundColor: '#ff1744',
        },
        selectedKeyItem: {
            backgroundColor: '#e6ffe6',
        },
        unselectedKeyItem: {
            backgroundColor: '#ffe6e6',
        },
    };


    const handleCloseKeysPopup = () => {
        closeAllPopups();
    };

    const fetchAllUsers = async () => {
        try {
            await executeRequestWithTokenRefresh(async () => {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    throw new Error('Токен доступа не найден');
                }

                const response = await axios.get<User[]>(`${API_URL}/users/users`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setAllUsers(response.data);
            });
        } catch (error) {
            console.error('Ошибка при получении пользователей:', error);
        }
    };

    const addUserToGroup = (user: User) => {
        setNewGroup(prev => {
            const updatedUsers = [...prev.users, user];
            const userCategoryKeys = filterCategoryKeys(user, categoryKeyValues);
            const updatedProcessingKey = Array.from(new Set([
                ...prev.processing_key,
                ...userCategoryKeys.map(key => key.id)
            ]));
            return {
                ...prev,
                users: updatedUsers,
                processing_key: updatedProcessingKey
            };
        });
    };

    const removeUserFromGroup = (userId: number) => {
        if (editingGroup) {
            const updatedUsers = editingGroup.users.filter(u => u.id !== userId);
            const updatedProcessingKey = Array.from(new Set(
                updatedUsers.flatMap(u =>
                    (u.category_keys || [])
                        .map(keyId => categoryKeyValues.find(kv => kv.id === keyId)?.key)
                        .filter((key): key is string => key !== undefined)
                )
            ));
            setEditingGroup({
                ...editingGroup,
                users: updatedUsers,
                processing_key: updatedProcessingKey
            });
        } else {
            setNewGroup(prev => {
                const updatedUsers = prev.users.filter(u => u.id !== userId);
                const updatedProcessingKey = Array.from(new Set(
                    updatedUsers.flatMap(u => u.category_keys || [])
                ));
                return {
                    ...prev,
                    users: updatedUsers,
                    processing_key: updatedProcessingKey
                };
            });
        }
    };

    const formatUsersForSelect = () => {
        const currentUsers = editingGroup ? editingGroup.users : newGroup.users;
        
        return allUsers
            .filter(user => 
                user.roles.includes('SYSTEM') && 
                user.is_active &&
                !currentUsers.some(groupUser => groupUser.id === user.id)
            )
            .map(user => ({
                value: user.id,
                label: `${user.first_name} ${user.last_name}`
            }));
    };

    const formatCategoryDirectorsForSelect = (): SelectOption[] => {
        return allUsers
            .filter(user => 
                user.roles.includes('CATEGORY_DIRECTOR') && 
                user.is_active
            )
            .map(director => ({
                value: director.id,
                label: `${director.first_name} ${director.last_name}`
            }));
    };

    const addDirectorToGroup = (director: User) => {
        setNewGroup(prev => ({
            ...prev,
            escalation_manager_id: director.id
        }));
    };

    const removeDirectorFromGroup = (directorId: number) => {
        setNewGroup(prev => ({
            ...prev,
            escalation_manager_id: null
        }));
    };

    const setProductManager = (user: User) => {
        setNewGroup(prev => ({
            ...prev,
            product_manager_id: user.id
        }));
    };

    const formatDepartmentHeadsForSelect = (): SelectOption[] => {
        return allUsers
            .filter(user => 
                user.roles.includes('CATEGORY_HEAD') &&
                user.is_active
            )
            .map(head => ({
                value: head.id,
                label: `${head.first_name} ${head.last_name}`
            }));
    };

    const selectStyles = {
        container: (baseStyles: any) => ({
            ...baseStyles,
            width: '100%',
            cursor: "pointer",
            padding: '0px',
            margin: '0 8px',
            border: 'none '
        }),
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#9B38DC' : 'gray',
            borderWidth: '2px',
            borderStyle: 'solid',
            boxShadow: state.isFocused ? '0 0 0 1px #9B38DC' : 'none',
            '&:hover': {
                borderColor: '#9B38DC'
            },
            minHeight: '38px',
            width: '100%',
        }),
        option: (baseStyles: any, state: any) => ({
            ...baseStyles,
            backgroundColor: state.isSelected ? '#9B38DC' : state.isFocused ? 'rgba(155, 56, 220, 0.1)' : 'white',
            color: state.isSelected ? 'white' : '#333',
            '&:active': {
                backgroundColor: '#7100b3',
                color: 'white'
            },
            textAlign: 'left',
        }),
        menu: (baseStyles: any) => ({
            ...baseStyles,
            backgroundColor: 'white',
            width: '100%',
            zIndex: 9999,
        }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles: any) => ({
            ...baseStyles,
            height: '38px',
            justifyContent: 'flex-start',
        }),
        singleValue: (baseStyles: any) => ({
            ...baseStyles,
            textAlign: 'left',
        }),
    };

    const filterCategoryKeys = (user: User, categoryKeyValues: CategoryKeyValue[]) => {
        if (!user.category_keys) return [];
        return categoryKeyValues.filter(keyValue => user.category_keys!.includes(keyValue.id));
    };

    const clearGroupStates = async () => {
        const defaultEscalationDays = modalMode === 'create' ? await fetchDefaultEscalationDays() : 0;
        
        setNewGroup({
            name: '',
            processing_key: [],
            escalation_manager_id: null,
            // escalation_days: defaultEscalationDays,
            users: [],
            category_director_id: null,
            product_manager_id: null,
        });
        setEditingGroup(null);
    };

    const fetchDefaultEscalationDays = async (): Promise<number> => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                throw new Error('Access token not found');
            }

            const response = await axios.get<number>(`${API_URL}/groups/escalation`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching default escalation days:', error);
            return 0;
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        clearGroupStates();
        setModalMode('create');
    };

    // @ts-ignore
    // @ts-ignore
    return (
        <div className={styles.container}>
            {isLoading && <Loader />}
            <div className={styles.sidebar}>
                <h2 className={styles.sidebarTitle}>Связки</h2>
                <ul className={styles.sidebarMenu}>
                    {pages.map((pageName, index) => (
                        <li
                            key={index}
                            className={`${styles.sidebarMenuItem} ${page === index ? styles.active : ''}`}
                            onClick={() => setPage(index)}
                        >
                            <Link to={`/ligaments?${pageName}`}>{pageName}</Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <h2>{pages[page]}</h2>
                    {pages[page] === 'Группы' && (
                        <button onClick={() => {
                            clearGroupStates();
                            setModalMode('create');
                            setIsModalOpen(true);
                        }} className={styles.createButton}>
                            <span className={styles.buttonText}>Создать новую группу</span>
                        </button>
                    )}
                </div>
                {pages[page] === 'Группы' && (
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Название</th>
                                <th>Категорийный ключ </th>
                                <th>Руководитель товарного направления</th>
                                <th>Пользователи</th>
                                <th>Дни эскалации</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups.map((group) => (
                                <tr key={group.id}>
                                    <td>{group.id}</td>
                                    <td>{group.name || 'Не указано'}</td>
                                    <td>{formatProcessingKey(group.processing_key, categoryKeyValues) || 'Не указано'}</td>
                                    <td>
                                        {group?.escalation_manager 
                                            ? `${group.escalation_manager.first_name} ${group.escalation_manager.last_name}`
                                            : 'Не указано'}
                                    </td>
                                    <td>
                                        <button
                                            onClick={() => handleShowUsers(group.id)}
                                            className={styles.editButton}
                                        >
                                            <img src={vector} alt=""/> Показать пользователей
                                        </button>
                                    </td>
                                    <td>{group.escalation_days || 'Не указано'}</td>
                                    <td>
                                        <button
                                            onClick={() => handleEditGroup(group)}
                                            className={styles.editButton}
                                        >
                                            Изменить
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {pages[page] === 'Категорийный ключ-пользователь' && (
                    <>
                        {error && <p className={styles.error}>{error}</p>}
                        <div className={styles.searchContainer}>
                            <span className={styles.searchIcon}><img src={loop} alt='loop'/></span>
                            <input
                                type="text"
                                placeholder="Поиск по ID, имени, фамилии или ключу"
                                value={searchTerm}
                                onChange={handleSearch}
                                className={styles.searchInput}
                            />
                        </div>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Имя</th>
                                    <th>Фамилия</th>
                                    <th>Категорийный ключ</th>
                                    <th>Действия</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredCategoryKeys.map((key) => (
                                    <tr key={key.id}>
                                        <td>{key.id || 'Не указано'}</td>
                                        <td>{key.first_name || 'Не указано'}</td>
                                        <td>{key.last_name || 'Не указано'}</td>
                                        <td>
                                            <button onClick={() => handleShowKeys(key.id)} className={styles.showKeysButton}>
                                                <img src={vector} alt=""/> Показать ключи
                                            </button>
                                            {activePopup === `keys-${key.id}` && (
                                                <div className={styles.keysPopup}>
                                                    <div className={styles.keysPopupHeader}>
                                                        <h3>Категорийные ключи</h3>
                                                        <button onClick={handleCloseKeysPopup} className={styles.closePopupButton}>×</button>
                                                    </div>
                                                    <ul className={styles.keysPopupList}>
                                                        {key.category_keys && key.category_keys.length > 0 ? (
                                                            key.category_keys.map((keyId) => (
                                                                <React.Fragment key={keyId}>
                                                                    <li className={styles.keysPopupItem}>
                                                                        {categoryKeyValues.find(kv => kv.id === keyId)?.key || 'Не указано'}
                                                                    </li>
                                                                    <hr style={{width:'100%'}}/>
                                                                </React.Fragment>
                                                            ))
                                                        ) : (
                                                            <li className={styles.keysPopupItem}>Не указано</li>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            <button onClick={() => handleEditCategoryKey(key)} className={styles.editButton}>
                                                 Изменить
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            {isModalOpen && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h2>{modalMode === 'edit' ? 'Редактировать группу' : 'Создать новую группу'}</h2>
                        <form onSubmit={editingGroup ? handleUpdateGroup : createGroup}
                              className={styles.editCategoryKeyForm}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="name">Название группы</label>
                                <input
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={editingGroup ? editingGroup.name : newGroup.name}
                                    onChange={(e) => editingGroup
                                        ? setEditingGroup({...editingGroup, name: e.target.value})
                                        : setNewGroup({...newGroup, name: e.target.value})
                                    }
                                    required
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label>Категорийные ключи</label>
                                <div className={styles.selectedKeys}>
                                    {formatProcessingKey(editingGroup ? editingGroup.processing_key : newGroup.processing_key, categoryKeyValues)
                                        .split(', ')
                                        .map((key, index) => (
                                            <span key={index} className={styles.keyTag}>
                                                 {key && key +" , "}
                                            </span>
                                        ))}
                                </div>
                            </div>
                            <div className={styles.inputGroup}>
                                <label>Пользователи</label>
                                <Select
                                    id="users"
                                    name="users"
                                    options={formatUsersForSelect()}
                                    value={null}
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            const selectedUser = allUsers.find(u => u.id === selectedOption.value && u.roles.includes('SYSTEM'));
                                            if (selectedUser) {
                                                if (editingGroup) {
                                                    const updatedUsers = [...editingGroup.users, selectedUser];
                                                    const updatedProcessingKey = Array.from(new Set([
                                                        ...(Array.isArray(editingGroup.processing_key) ? editingGroup.processing_key : [editingGroup.processing_key]),
                                                        ...(selectedUser.category_keys || [])
                                                            .map(keyId => categoryKeyValues.find(kv => kv.id === keyId)?.key)
                                                            .filter((key): key is string => key !== undefined)
                                                    ]));
                                                    setEditingGroup({
                                                        ...editingGroup,
                                                        users: updatedUsers,
                                                        processing_key: updatedProcessingKey
                                                    });
                                                } else {
                                                    addUserToGroup(selectedUser);
                                                }
                                            }
                                        }
                                    }}
                                    placeholder="Выберите пользователей"
                                    className={styles.selectUsers}
                                    classNamePrefix="select"
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                />
                            </div>
                            {((editingGroup ? editingGroup.users : newGroup.users) || []).length > 0 && (
                                <div className={styles.selectedUsers}>
                                    {(editingGroup ? editingGroup.users : newGroup.users).map(user => (
                                        <div key={user.id} className={styles.userTag}>
                                            {user.first_name} {user.last_name}
                                            <button type="button" onClick={() => {
                                                if (editingGroup) {
                                                    const updatedUsers = editingGroup.users.filter(u => u.id !== user.id);
                                                    const updatedProcessingKey = Array.from(new Set(
                                                        updatedUsers.flatMap(u =>
                                                            (u.category_keys || [])
                                                                .map(keyId => categoryKeyValues.find(kv => kv.id === keyId)?.key)
                                                                .filter((key): key is string => key !== undefined)
                                                        )
                                                    ));
                                                    setEditingGroup({
                                                        ...editingGroup,
                                                        users: updatedUsers,
                                                        processing_key: updatedProcessingKey
                                                    });
                                                } else {
                                                    removeUserFromGroup(user.id);
                                                }
                                            }}>×</button>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className={styles.inputGroup}>
                                <label>Руководитель товарного направления</label>
                                <Select
                                    id="product_manager"
                                    name="product_manager"
                                    options={formatDepartmentHeadsForSelect()}
                                    value={editingGroup && editingGroup.escalation_manager
                                        ? {
                                            value: editingGroup.escalation_manager.id,
                                            label: `${editingGroup.escalation_manager.first_name} ${editingGroup.escalation_manager.last_name}`
                                        }
                                        : newGroup.product_manager_id
                                            ? {
                                                value: newGroup.product_manager_id,
                                                label: allUsers.find(u => u.id === newGroup.product_manager_id && u.roles.includes('CATEGORY_HEAD'))?.first_name + ' ' +
                                                       allUsers.find(u => u.id === newGroup.product_manager_id && u.roles.includes('CATEGORY_HEAD'))?.last_name
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            const selectedUser = allUsers.find(u => u.id === (selectedOption as SelectOption).value && u.roles.includes('CATEGORY_HEAD'));
                                            if (selectedUser) {
                                                if (editingGroup) {
                                                    setEditingGroup({...editingGroup, escalation_manager: {id: selectedUser.id, first_name: selectedUser.first_name, last_name: selectedUser.last_name}});
                                                } else {
                                                    setProductManager(selectedUser);
                                                }
                                            }
                                        } else {
                                            if (editingGroup) {
                                                setEditingGroup({...editingGroup, escalation_manager: {id: 0, first_name: '', last_name: ''}});
                                            } else {
                                                setNewGroup(prev => ({ ...prev, product_manager_id: null }));
                                            }
                                        }
                                    }}
                                    placeholder="Выберите Руководителя товарного направления"
                                    className={styles.selectUsers}
                                    classNamePrefix="select"
                                    isClearable={true}
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label>Категорийный директор</label>
                                <Select
                                    id="category_director_id"
                                    name="category_director_id"
                                    options={formatCategoryDirectorsForSelect()}
                                    value={
                                        editingGroup 
                                            ? (editingGroup.category_director_id !== null
                                                ? {
                                                    value: editingGroup.category_director_id,
                                                    label: `${editingGroup.escalation_director?.first_name} ${editingGroup.escalation_director?.last_name}`
                                                }
                                                : null)
                                            : (newGroup.category_director_id !== null
                                                ? {
                                                    value: newGroup.category_director_id,
                                                    label: allUsers.find(u => u.id === newGroup.category_director_id)?.first_name + ' ' +
                                                           allUsers.find(u => u.id === newGroup.category_director_id)?.last_name
                                                }
                                                : null)
                                    }
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            const selectedId = (selectedOption as SelectOption).value;
                                            const selectedDirector = allUsers.find(user => 
                                                user.id === selectedId && user.roles.includes('CATEGORY_DIRECTOR')
                                            );
                                            
                                            if (editingGroup) {
                                                setEditingGroup({
                                                    ...editingGroup,
                                                    category_director_id: selectedId,
                                                    escalation_director: selectedDirector
                                                        ? { id: selectedId, first_name: selectedDirector.first_name, last_name: selectedDirector.last_name }
                                                        : null
                                                });
                                            } else {
                                                setNewGroup(prev => ({
                                                    ...prev,
                                                    category_director_id: selectedId
                                                }));
                                            }
                                        } else {
                                            if (editingGroup) {
                                                setEditingGroup({
                                                    ...editingGroup, 
                                                    category_director_id: null, 
                                                    escalation_director: null
                                                });
                                            } else {
                                                setNewGroup(prev => ({
                                                    ...prev,
                                                    category_director_id: null
                                                }));
                                            }
                                        }
                                    }}
                                    placeholder="Выберите Категорийного директора"
                                    className={styles.selectUsers}
                                    classNamePrefix="select"
                                    isClearable={true}
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                />
                            </div>
                            {/*<div className={styles.inputGroup}>*/}
                            {/*    <label htmlFor="escalation_days">Дни эскалации</label>*/}
                            {/*    <input*/}
                            {/*        id="escalation_days"*/}
                            {/*        type="number"*/}
                            {/*        name="escalation_days"*/}
                            {/*        value={editingGroup ? editingGroup.escalation_days : newGroup.escalation_days}*/}
                            {/*        onChange={(e) => editingGroup*/}
                            {/*            ? setEditingGroup({...editingGroup, escalation_days: parseInt(e.target.value)})*/}
                            {/*            : setNewGroup({...newGroup, escalation_days: parseInt(e.target.value)})*/}
                            {/*        }*/}
                            {/*        required*/}
                            {/*    />*/}
                            {/*</div>*/}

                            <div className={styles.modalButtons}>
                                <button type="submit" className={styles.save}>
                                    {editingGroup ? 'Обновить' : 'Создать'}
                                </button>
                                {editingGroup && (
                                    <button type="button" className={styles.otmena} onClick={handleDeleteGroup}>
                                        Удалить группу
                                    </button>
                                )}
                                <button type="button" className={styles.otmena} onClick={handleCloseModal}>
                                    Отмена
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {isEditModalOpen && editingCategoryKey && (
                <div className={styles.modal}>
                    <div className={styles.modalContent} ref={modalRef}>
                        <h2>Изменить категорийный ключ</h2>
                        <form onSubmit={updateCategoryKey} className={styles.editCategoryKeyForm}>
                            <div className={styles.inputGroup}>
                                <input
                                    placeholder='Поиск категорийных ключей'
                                    id="categoryKeySearch"
                                    type="text"
                                    value={categoryKeySearchTerm}
                                    onChange={handleCategoryKeySearch}
                                    className={styles.categoryKeySearchInput}
                                />
                            </div>
                            {/*<div className={styles.availableKeysWrapper}>*/}
                            <label>Доступные ключи:</label>
                            {/*<div className={styles.keysListContainer}>*/}
                            <div className={styles.keysList}>
                                {filteredCategoryKeyValues.map(kv => (
                                    <div
                                        key={kv.id}
                                        style={{
                                            ...keyItemStyles.keyItem,
                                            ...(editingCategoryKey.category_keys.includes(kv.id)
                                                ? keyItemStyles.selectedKeyItem
                                                : keyItemStyles.unselectedKeyItem)
                                        }}
                                    >
                                        <span style={keyItemStyles.keyItemContent}>{kv.key}</span>
                                        <button
                                            type="button"
                                            onClick={() => handleAddOrRemoveKey(kv.id, kv.key)}
                                            style={{
                                                ...keyItemStyles.addRemoveKeyButton,
                                                ...(editingCategoryKey.category_keys.includes(kv.id)
                                                    ? keyItemStyles.removeButton
                                                    : keyItemStyles.addButton)
                                            }}
                                        >
                                            {editingCategoryKey.category_keys.includes(kv.id) ? '−' : '+'}
                                        </button>
                                    </div>
                                ))}
                            </div>
                            {/*</div>*/}
                            {/*</div>*/}
                            <div className={styles.modalButtons}>
                                <button type="submit" className={styles.save}>Сохранить</button>
                                <button type="button" className={styles.otmena} onClick={() => {
                                    setIsEditModalOpen(false);
                                    setEditingCategoryKey(null);
                                    setCategoryKeySearchTerm('');
                                }}>Отмена
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {showUsersPopup && (
                <div className={styles.usersPopup} ref={modalRef}>
                    <div className={styles.usersPopupInner}>
                        <div className={styles.usersPopupHeader}>
                            <h3>Пользователи</h3>
                            <button onClick={() => setShowUsersPopup(null)} className={styles.closePopupButton}>×</button>
                        </div>
                        <div className={styles.usersPopupContent}>
                            <table className={styles.usersPopupTable}>
                                <thead>
                                    <tr>
                                        <th>Выбрать</th>
                                        <th>Имя и Фамилия</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupUsers
                                        .filter(user => 
                                            user.roles.includes('SYSTEM') && 
                                            user.is_active
                                        )
                                        .map((user) => (
                                            <tr key={user.id} className={`${styles.usersPopupItem} ${selectedUsers.includes(user.id) ? styles.selectedUserRow : ''}`}>
                                                <td>
                                                    <button
                                                        onClick={() => handleUserSelection(user.id)}
                                                        className={`${styles.toggleUserButton} ${selectedUsers.includes(user.id) ? styles.selectedUser : ''}`}
                                                    >
                                                        {selectedUsers.includes(user.id) ? '−' : '+'}
                                                    </button>
                                                </td>
                                                <td>{`${user.first_name} ${user.last_name}`}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={styles.usersPopupFooter}>
                            <button onClick={handleSaveUsers} className={styles.saveUsersButton}>Сохранить</button>
                        </div>
                    </div>
                </div>
            )}
            {showDeleteConfirmation && (
                <div className={styles.modal}>
                    <div className={styles.modalContent} style={{ width: '450px' }}>
                        <h2 style={{marginBottom:'20px'}}>Подтверждение удаления</h2>
                        <p>Вы уверены, что хотите удалить эту группу?</p>
                        <div className={styles.modalButtons}>
                            <button onClick={confirmDeleteGroup} className={styles.save}>Да, удалить</button>
                            <button onClick={cancelDeleteGroup} className={styles.otmena}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Ligaments;
