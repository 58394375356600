import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './Applications.module.css'
import EditUserModal from '../../components/EditUserModal'
import CreateUserModal from '../../components/CreateUserModal'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {API_URL} from "../../features/auth/authSlice";
import Loader from '../../components/Loader';
import useLoader from '../../hooks/useLoader';

const MessageModal = ({ message, onClose }) => (
    <div className={styles.messageModal}>
        <p>{message}</p>
        <button onClick={onClose}>Закрыть</button>
    </div>
);

const SuccessModal = ({ message, onClose }) => (
    <div className={styles.successModal}>
        <p>{message}</p>
        <button onClick={onClose}>Закрыть</button>
    </div>
);

const roleTranslations = {
    'ADMIN': 'Администратор',
    'ANALYST': 'Аналитик департамента коммерческой аналитики',
    // 'CATEGORY_HEAD': 'Руководитель категории',
    'CATEGORY_DIRECTOR': 'Категорийный директор',
    'CATEGORY_HEAD': 'Руководитель товарного направления',
    'SYSTEM':'Ответственный за категории',

    'TRADE_MARKETING': 'Трейд-маркетинг',
    'PRICING_SPECIALIST': 'Специалист по ценообразованию',
    'FINANCIAL_CONTROLLER': 'Финансовый контролер',
    'MARKETING_SPECIALIST': 'Маркетолог',
    'LOGISTICS_SPECIALIST': 'Специалист по логистике'
};

async function executeRequestWithTokenRefresh(requestFunction) {
    try {
        return await requestFunction();
    } catch (error) {
        if (error.response && error.response.status === 401) {
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await axios.post(`${API_URL}/auth/refresh`, { refresh_token: refreshToken });
                localStorage.setItem('accessToken', response.data.access_token);
                return await requestFunction();
            } catch (refreshError) {
                console.error('Error refreshing token:', refreshError);
                throw refreshError;
            }
        }
        throw error;
    }
}

export default function Applications() {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [editingUser, setEditingUser] = useState(null);
    const [isCreatingUser, setIsCreatingUser] = useState(false);
    const navigate = useNavigate();
    const { isLoading, showLoader, hideLoader } = useLoader();
    const [newUserId, setNewUserId] = useState(null);
    const [newUserGroupData, setNewUserGroupData] = useState(null);
    const [newUserGroupId, setNewUserGroupId] = useState(null);

    useEffect(() => {
        const userRole = localStorage.getItem('userRole');
        if (userRole !== 'ADMIN') {
            navigate('/dashboard');
            return;
        }
        fetchUsers();
    }, [navigate]);

    useEffect(() => {
        const updateGroupWithUser = async () => {
            if (newUserId && newUserGroupId && newUserGroupData) {
                try {
                    const accessToken = localStorage.getItem('accessToken');
                    const updateGroupData = {
                        ...newUserGroupData,
                        user_ids: [newUserId]
                    };

                    console.log('Updating group with user ID:', updateGroupData);

                    await executeRequestWithTokenRefresh(async () => {
                        await axios.put(
                            `${API_URL}/groups/groups/${newUserGroupId}`,
                            updateGroupData,
                            {
                                headers: {
                                    'Authorization': `Bearer ${accessToken}`,
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                    });

                    setNewUserId(null);
                    setNewUserGroupData(null);
                    setNewUserGroupId(null);
                } catch (error) {
                    console.error('Error updating group:', error);
                }
            }
        };

        updateGroupWithUser();
    }, [newUserId, newUserGroupId, newUserGroupData]);

    const fetchUsers = async () => {
        showLoader();
        try {
            const users = await executeRequestWithTokenRefresh(async () => {
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(`${API_URL}/users/users`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                return response.data;
            });
            setUsers(users);
            setError(null);
        } catch (error) {
            console.error('Ошибка при получении пользователей:', error);
            if (error.response?.status === 403) {
                setError('У вас недостаточно прав для просмотра пользователей');
            } else {
                setError(error.message);
            }
            setUsers([]);
        } finally {
            hideLoader();
        }
    };

    const handleEditUser = (user) => {
        setEditingUser(user);
    };

    const handleCloseEditModal = () => {
        setEditingUser(null);
        fetchUsers();
    };

    const handleUpdateUser = async (updatedUser) => {
        showLoader();
        try {
            await executeRequestWithTokenRefresh(async () => {
                const token = localStorage.getItem('accessToken');
                await axios.put(`${API_URL}/users/users/${updatedUser.id}`, updatedUser, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
            });
            
            await fetchUsers();
            setEditingUser(null);
        } catch (error) {
            console.error('Ошибка при обновлении пользователя:', error);
            if (error.response?.status === 403) {
                setError('У вас недостаточно прав для редактирования пользователей');
            } else {
                setError(error.message);
            }
        } finally {
            hideLoader();
        }
    };

    const handleCreateUser = () => {
        setIsCreatingUser(true);
    };

    const handleCloseCreateModal = () => {
        setIsCreatingUser(false);
    };

    const handleUserCreated = async (newUser) => {
        showLoader();
        try {
            const userData = {
                email: newUser.email.trim(),
                first_name: newUser.first_name.trim(),
                last_name: newUser.last_name.trim(),
                password: newUser.password,
                roles: Array.isArray(newUser.roles) ? newUser.roles : [newUser.roles],
                category_keys: Array.isArray(newUser.category_keys) ? newUser.category_keys : []
            };

            console.log('Sending formatted user data:', userData);

            const createdUser = await executeRequestWithTokenRefresh(async () => {
                const token = localStorage.getItem('accessToken');
                const response = await axios.post(
                    `${API_URL}/users/users`,
                    userData,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                return response.data;
            });

            setUsers([...users, createdUser]);
            setIsCreatingUser(false);
            return createdUser;

        } catch (error) {
            console.error('Error creating user:', error);
            if (error.response?.data) {
                console.error('Server error details:', error.response.data);
            }
            if (error.response?.status === 403) {
                setError('У вас недостаточно прав для создания пользователей');
            } else if (error.response?.status === 400) {
                if (error.response.data.detail === 'User with this email already exists') {
                    setError('Пользователь с таким email уже существует');
                } else {
                    setError(error.response.data.detail || 'Некорректные данные. Убедитесь, что используется корпоративная почта.');
                }
            } else {
                setError(error.message);
            }
            throw error;
        } finally {
            hideLoader();
        }
    };

    const handleDeleteUser = async (userId) => {
        confirmAlert({
            title: 'Подтвердите удаление',
            message: 'Вы уверены, что хотите удалить этого пользователя?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        showLoader();
                        try {
                            await executeRequestWithTokenRefresh(async () => {
                                const token = localStorage.getItem('accessToken');
                                await axios.delete(`${API_URL}/users/users/${userId}`, {
                                    headers: {
                                        'Authorization': `Bearer ${token}`,
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    },
                                    data: `user_id=${userId}`
                                });
                            });
                            setUsers(users.filter(user => user.id !== userId));
                            setSuccessMessage('Пользователь успешно удален');
                        } catch (error) {
                            console.error('Ошибка при удалении пользователя:', error);
                            if (error.response?.status === 403) {
                                setError('У вас недостаточно прав для удаления пользователей');
                            } else {
                                setError(`Ошибка при удалении пользователя: ${error.message}`);
                            }
                        } finally {
                            hideLoader();
                        }
                    },
                    className: 'confirm-button'
                },
                {
                    label: 'Нет',
                    onClick: () => {},
                    className: 'cancel-button'
                }
            ],
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'style={{textAlign:'center'}}>
                        <h1>Подтвердите удаление</h1>
                        <p style={{margin:'10px 0'}}>Вы уверены, что хотите удалить этого пользователя?</p>
                        <div style={{display:'flex',justifyContent:'center',textAlign:'center'}}>
                            <button
                                className={'createButton'}
                                onClick={() => {
                                    handleDeleteConfirm(userId);
                                    onClose();
                                }}
                            >
                                Да
                            </button>
                            <button className={styles.confirm_button2} onClick={onClose}>
                                Нет
                            </button>
                        </div>

                    </div>
                );
            }
        });
    };

    const handleDeleteConfirm = async (userId) => {
        showLoader();
        try {
            await executeRequestWithTokenRefresh(async () => {
                const token = localStorage.getItem('accessToken');
                await axios.delete(`${API_URL}/users/users/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: `user_id=${userId}`
                });
            });
            setUsers(users.filter(user => user.id !== userId));
            setSuccessMessage('Пользователь успешно удален');
        } catch (error) {
            console.error('Ошибка при удалении пользователя:', error);
            if (error.response?.status === 403) {
                setError('У вас недостаточно прав для удаления пользователей');
            } else {
                setError(`Ошибка при удалении пользователя: ${error.message}`);
            }
        } finally {
            hideLoader();
        }
    };

    return (
        <div className={styles.pageContainer}>
            {isLoading && <Loader />}
            {error && <MessageModal message={error} onClose={() => setError(null)} />}
            {successMessage && <SuccessModal message={successMessage} onClose={() => setSuccessMessage(null)} />}
            <div className={styles.tableContainer}>
                <div className={styles.top_block}><h1>Пользователи</h1>
                    <button className={'createButton'} onClick={handleCreateUser}>Создать пользователя</button>
                </div>

                <table className={styles.table}>
                    <thead>
                    <tr>
                    <th>ID</th>
                            <th>Имя</th>
                            <th>Фамилия</th>
                            <th>Email</th>
                            <th>Статус</th>
                            <th>Роли</th>
                            <th>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.first_name}</td>
                                <td>{user.last_name}</td>
                                <td>{user.email}</td>
                                <td className={user.is_active ? styles.activ : styles.foled}>{user.is_active ? 'Активен' : 'Не активен'}</td>
                                <td>{user.roles.map(role => roleTranslations[role] || role).join(', ')}</td>
                                <td>
                                    <button className={styles.editButton} onClick={() => handleEditUser(user)}>Изменить</button>
                                    <button className={styles.deleteButton} onClick={() => handleDeleteUser(user.id)}>Удалить</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {editingUser && (
                <EditUserModal
                    user={editingUser}
                    onClose={handleCloseEditModal}
                    onUpdate={handleUpdateUser}
                />
            )}
            {isCreatingUser && (
                <CreateUserModal
                    onClose={handleCloseCreateModal}
                    onCreateUser={handleUserCreated}
                />
            )}
        </div>
    )
}
