import axios from 'axios';
import { config } from '../config/environment';

export const API_URL = config.API_URL;
const FRONT_URL = config.FRONT_URL;

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface LoginResponse {
  user_id: number;
  require_2fa: boolean;
  roles: string[];
}

export interface RegisterData {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  roles: string[];
  category_keys: string[];
}

export const AuthService = {
  login: async (credentials: LoginCredentials): Promise<LoginResponse> => {
    const params = new URLSearchParams();
    params.append('username', credentials.email);
    params.append('password', credentials.password);

    const response = await axios.post(`${API_URL}/auth/login`, params, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Origin': FRONT_URL,
        'Referer': `${FRONT_URL}/`
      }
    });

    return {
      user_id: response.data.user_id,
      require_2fa: response.data.require_2fa,
      roles: response.data.roles || []
    };
  },

  register: async (userData: RegisterData) => {
    const response = await axios.post(`${API_URL}/auth/register`, userData, {
      headers: {
        'Content-Type': 'application/json',
        'Origin': FRONT_URL,
        'Referer': `${FRONT_URL}/`
      }
    });
    return response;
  }
};