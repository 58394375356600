import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../features/auth/authSlice';
import styles from './EditUserModal.module.css';
import Select from "react-select";

const ROLES = {
    ADMIN: "Администратор",
    ANALYST: "Аналитик департамента коммерческой аналитики",
    CATEGORY_DIRECTOR: "Категорийный директор",
    CATEGORY_HEAD: "Руководитель товарного направления",
    SYSTEM:'Ответственный за категории'
};

const EditUserModal = ({ user, onClose, onUpdate, allUsers = [] }) => {
    const [editedUser, setEditedUser] = useState(() => {
        return {
            ...user,
            category_keys: user.category_keys || []
        };
    });
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [categoryKeys, setCategoryKeys] = useState([]);
    const [departmentHeads, setDepartmentHeads] = useState([]);
    const [categoryDirectors, setCategoryDirectors] = useState([]);
    const [groupData, setGroupData] = useState(null);

    useEffect(() => {
        document.body.classList.add('modal-open');
        
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, []);

    useEffect(() => {
        const fetchCategoryKeys = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axios.get(`${API_URL}/users/category_keys`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setCategoryKeys(response.data);
            } catch (error) {
                console.error('Error fetching category keys:', error);
            }
        };

        fetchCategoryKeys();
    }, []);

    useEffect(() => {
        if (user.linked_users) {
            setSelectedUserIds(user.linked_users.map(u => u.id));
        }
    }, [user]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axios.get(`${API_URL}/users/users`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                
                const heads = response.data.filter(user => 
                    user.roles.includes('CATEGORY_HEAD')
                ).map(user => ({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name}`
                }));
                
                const directors = response.data.filter(user => 
                    user.roles.includes('CATEGORY_DIRECTOR')
                ).map(user => ({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name}`
                }));
                
                setDepartmentHeads(heads);
                setCategoryDirectors(directors);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchGroupData = async () => {
            if (user.groups && user.groups.length > 0) {
                try {
                    const accessToken = localStorage.getItem('accessToken');
                    const response = await axios.get(
                        `${API_URL}/groups/groups/${user.groups[0]}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        }
                    );
                    setGroupData(response.data);
                    
                    setEditedUser(prev => ({
                        ...prev,
                        department_head_id: response.data.escalation_manager?.id || prev.department_head_id,
                        category_director_id: response.data.escalation_director?.id || prev.category_director_id
                    }));
                } catch (error) {
                    console.error('Error fetching group data:', error);
                }
            }
        };

        fetchGroupData();
    }, [user.groups]);

    useEffect(() => {
        console.log('Current department_head_id:', editedUser.department_head_id);
        console.log('Group Data:', groupData);
        console.log('Department Heads options:', departmentHeads);
    }, [editedUser.department_head_id, groupData, departmentHeads]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEditedUser(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const roleOptions = Object.entries(ROLES).map(([key, value]) => ({
        value: key,
        label: value
    }));

    const handleRoleChange = (selectedOption) => {
        setEditedUser(prev => ({ ...prev, roles: [selectedOption.value] }));
    };

    const handleUserSelect = (e) => {
        const selectedIds = Array.from(e.target.selectedOptions, option => Number(option.value));
        setSelectedUserIds(selectedIds);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const userUpdateData = {
            email: editedUser.email,
            first_name: editedUser.first_name,
            last_name: editedUser.last_name,
            roles: editedUser.roles,
            category_keys: editedUser.category_keys || [],
            is_active: editedUser.is_active
        };

        try {
            const accessToken = localStorage.getItem('accessToken');
            await axios.put(
                `${API_URL}/users/users/${editedUser.id}`,
                userUpdateData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if ((editedUser.roles?.[0] === 'SYSTEM' || editedUser.roles?.[0] === 'DEPARTMENT_HEAD') && 
                editedUser.category_keys?.length > 0) {
                try {
                    const groupData = {
                        name: categoryKeys
                            .filter(key => editedUser.category_keys.includes(key.id))
                            .map(key => key.key)
                            .join(', '),
                        processing_key: categoryKeys
                            .filter(key => editedUser.category_keys.includes(key.id))
                            .map(key => key.key),
                        escalation_manager_id: editedUser.roles?.[0] === 'SYSTEM' ? 
                            editedUser.department_head_id : 
                            editedUser.id,
                        escalation_director_id: editedUser.category_director_id,
                        user_ids: [editedUser.id]
                    };

                    if (user.groups && user.groups.length > 0) {
                        await axios.put(
                            `${API_URL}/groups/groups/${user.groups[0]}`,
                            groupData,
                            {
                                headers: {
                                    'Authorization': `Bearer ${accessToken}`,
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                    } else {
                        await axios.post(
                            `${API_URL}/groups/groups`,
                            groupData,
                            {
                                headers: {
                                    'Authorization': `Bearer ${accessToken}`,
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                    }
                } catch (error) {
                    console.error('Error managing group:', error);
                }
            }

            onClose();
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const categoryKeyOptions = categoryKeys.map(key => ({
        value: key.id,
        label: key.key
    }));

    const handleCategoryKeyChange = (selectedOption) => {
        if (!selectedOption) return;
        
        if (Array.isArray(selectedOption)) {
            const newKeyId = selectedOption[0].value;
            const currentKeys = Array.isArray(editedUser.category_keys) ? editedUser.category_keys : [];
            
            if (!currentKeys.includes(newKeyId)) {
                const updatedKeys = [...currentKeys, newKeyId];
                setEditedUser(prev => ({
                    ...prev,
                    category_keys: updatedKeys
                }));
            }
        }
    };

    const handleDepartmentHeadChange = (selectedOption) => {
        console.log('Selected department head:', selectedOption);
        setEditedUser(prev => ({
            ...prev,
            department_head_id: selectedOption ? selectedOption.value : null
        }));
    };

    const handleCategoryDirectorChange = (selectedOption) => {
        setEditedUser(prev => ({
            ...prev,
            category_director_id: selectedOption?.value
        }));
    };

    const shouldShowField = (fieldName) => {
        const currentRole = editedUser.roles?.[0];
        
        switch (fieldName) {
            case 'category_key':
                return ['SYSTEM', 'CATEGORY_HEAD', 'CATEGORY_DIRECTOR'].includes(currentRole);
                
            case 'CATEGORY_HEAD':
                return currentRole === 'SYSTEM';
                
            case 'category_director':
                return ['SYSTEM', 'CATEGORY_HEAD'].includes(currentRole);
                
            default:
                return false;
        }
    };

    const handleRemoveKey = (keyId) => {
        const updatedKeys = editedUser.category_keys.filter(id => id !== keyId);
        setEditedUser(prev => ({
            ...prev,
            category_keys: updatedKeys
        }));
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>Редактировать пользователя</h2>
                <form onSubmit={handleSubmit}>
                    <div className={styles.formGroup}>
                        <label htmlFor="first_name">Имя:</label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={editedUser.first_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="last_name">Фамилия:</label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={editedUser.last_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={editedUser.email}
                            onChange={handleChange}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <label htmlFor="roles">Роль</label>
                        <Select
                            id="roles"
                            name="roles"
                            options={roleOptions}
                            value={roleOptions.find(option => editedUser.roles.includes(option.value))}
                            onChange={handleRoleChange}
                            className={styles.selectRoles}
                            classNamePrefix="select"
                            styles={{
                                container: (baseStyles) => ({
                                    ...baseStyles,
                                    width: '100%',
                                    cursor: "pointer"
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? '#9B38DC' : '#ccc',
                                    boxShadow: state.isFocused ? '0 0 0 1px #9B38DC' : 'none',
                                    '&:hover': {
                                        borderColor: '#9B38DC'
                                    },
                                    minHeight: '38px',
                                    width: '100%',
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isSelected ? '#9B38DC' : state.isFocused ? 'rgba(155, 56, 220, 0.1)' : 'white',
                                    color: state.isSelected ? 'white' : '#333',
                                    '&:active': {
                                        backgroundColor: '#7100b3',
                                        color: 'white'
                                    },
                                    textAlign: 'left',
                                }),
                                menu: (baseStyles) => ({
                                    ...baseStyles,
                                    backgroundColor: 'white',
                                    width: '100%',
                                }),
                                valueContainer: (baseStyles) => ({
                                    ...baseStyles,
                                    height: '38px',
                                    justifyContent: 'flex-start',
                                }),
                                singleValue: (baseStyles) => ({
                                    ...baseStyles,
                                    textAlign: 'left',
                                }),
                            }}
                        />
                    </div>
                    {shouldShowField('category_key') && (
                        <>
                            <div className={styles.formGroup}>
                                <label htmlFor="category_key">Категорийный ключ</label>
                                <Select
                                    id="category_key"
                                    name="category_key"
                                    options={categoryKeyOptions}
                                    value={[]}
                                    onChange={handleCategoryKeyChange}
                                    className={styles.selectRoles}
                                    classNamePrefix="select"
                                    placeholder="Выберите категорийные ключи"
                                    isMulti={true}
                                    styles={{
                                        container: (baseStyles) => ({
                                            ...baseStyles,
                                            width: '100%',
                                            cursor: "pointer"
                                        }),
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? '#9B38DC' : '#ccc',
                                            boxShadow: state.isFocused ? '0 0 0 1px #9B38DC' : 'none',
                                            '&:hover': {
                                                borderColor: '#9B38DC'
                                            },
                                            minHeight: '38px',
                                            width: '100%',
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: state.isSelected ? '#9B38DC' : state.isFocused ? 'rgba(155, 56, 220, 0.1)' : 'white',
                                            color: state.isSelected ? 'white' : '#333',
                                            '&:active': {
                                                backgroundColor: '#7100b3',
                                                color: 'white'
                                            },
                                            textAlign: 'left',
                                        }),
                                        menu: (baseStyles) => ({
                                            ...baseStyles,
                                            backgroundColor: 'white',
                                            width: '100%',
                                        }),
                                        valueContainer: (baseStyles) => ({
                                            ...baseStyles,
                                            minHeight: '38px',
                                            justifyContent: 'flex-start',
                                        }),
                                        singleValue: (baseStyles) => ({
                                            ...baseStyles,
                                            textAlign: 'left',
                                        }),
                                        multiValue: (baseStyles) => ({
                                            ...baseStyles,
                                            backgroundColor: 'rgba(155, 56, 220, 0.1)',
                                        }),
                                        multiValueLabel: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#333',
                                        }),
                                        multiValueRemove: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#666',
                                            '&:hover': {
                                                backgroundColor: '#9B38DC',
                                                color: 'white',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            {Array.isArray(editedUser.category_keys) && editedUser.category_keys.length > 0 && (
                                <div className={styles.selectedKeysContainer}>
                                    <div className={styles.selectedKeys}>
                                        {categoryKeyOptions
                                            .filter(option => editedUser.category_keys.includes(option.value))
                                            .map(option => {
                                                const key = categoryKeys.find(k => k.id === option.value);
                                                return (
                                                    <div key={option.value} className={styles.selectedKey}>
                                                        <span className={styles.keyLabel}>{key?.key}</span>
                                                        <button 
                                                            type="button"
                                                            className={styles.removeKeyButton}
                                                            onClick={() => handleRemoveKey(option.value)}
                                                        >
                                                            ×
                                                        </button>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {shouldShowField('CATEGORY_HEAD') && (
                        <div className={styles.formGroup}>
                            <label htmlFor="CATEGORY_HEAD">Руководитель товарного направления</label>
                            <Select
                                id="CATEGORY_HEAD"
                                name="CATEGORY_HEAD"
                                options={departmentHeads}
                                value={departmentHeads.find(option => 
                                    option.value === editedUser.department_head_id
                                )}
                                onChange={handleDepartmentHeadChange}
                                className={styles.selectRoles}
                                classNamePrefix="select"
                                placeholder='Выберите руководителя'
                                styles={{
                                    container: (baseStyles) => ({
                                        ...baseStyles,
                                        width: '100%',
                                        cursor: "pointer"
                                    }),
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#9B38DC' : '#ccc',
                                        boxShadow: state.isFocused ? '0 0 0 1px #9B38DC' : 'none',
                                        '&:hover': {
                                            borderColor: '#9B38DC'
                                        },
                                        minHeight: '38px',
                                        width: '100%',
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? '#9B38DC' : state.isFocused ? 'rgba(155, 56, 220, 0.1)' : 'white',
                                        color: state.isSelected ? 'white' : '#333',
                                        '&:active': {
                                            backgroundColor: '#7100b3',
                                            color: 'white'
                                        },
                                        textAlign: 'left',
                                    }),
                                    menu: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: 'white',
                                        width: '100%',
                                    }),
                                    valueContainer: (baseStyles) => ({
                                        ...baseStyles,
                                        height: '38px',
                                        justifyContent: 'flex-start',
                                    }),
                                    singleValue: (baseStyles) => ({
                                        ...baseStyles,
                                        textAlign: 'left',
                                    }),
                                }}
                            />
                        </div>
                    )}
                    {shouldShowField('category_director') && (
                        <div className={styles.formGroup}>
                            <label htmlFor="category_director">Категорийный директор</label>
                            <Select
                                id="category_director"
                                name="category_director"
                                options={categoryDirectors}
                                value={categoryDirectors.find(option => 
                                    option.value === (groupData?.escalation_director?.id || editedUser.category_director_id)
                                )}
                                onChange={handleCategoryDirectorChange}
                                className={styles.selectRoles}
                                classNamePrefix="select"
                                placeholder="Выберите директора"
                                styles={{
                                    container: (baseStyles) => ({
                                        ...baseStyles,
                                        width: '100%',
                                        cursor: "pointer"
                                    }),
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#9B38DC' : '#ccc',
                                        boxShadow: state.isFocused ? '0 0 0 1px #9B38DC' : 'none',
                                        '&:hover': {
                                            borderColor: '#9B38DC'
                                        },
                                        minHeight: '38px',
                                        width: '100%',
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? '#9B38DC' : state.isFocused ? 'rgba(155, 56, 220, 0.1)' : 'white',
                                        color: state.isSelected ? 'white' : '#333',
                                        '&:active': {
                                            backgroundColor: '#7100b3',
                                            color: 'white'
                                        },
                                        textAlign: 'left',
                                    }),
                                    menu: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: 'white',
                                        width: '100%',
                                    }),
                                    valueContainer: (baseStyles) => ({
                                        ...baseStyles,
                                        height: '38px',
                                        justifyContent: 'flex-start',
                                    }),
                                    singleValue: (baseStyles) => ({
                                        ...baseStyles,
                                        textAlign: 'left',
                                    }),
                                }}
                            />
                        </div>
                    )}
                    <div className={styles.formGroup}>
                        <label className={styles.checkboxContainer}>
                            <span className={styles.checkboxLabel}>Активен</span>
                            <div className={styles.customCheckbox}>
                                <input
                                    type="checkbox"
                                    name="is_active"
                                    checked={editedUser.is_active}
                                    onChange={handleChange}
                                />
                                <span className={styles.checkboxSlider}></span>
                            </div>
                        </label>
                    </div>
                    {allUsers && allUsers.length > 0 && (
                        <div className={styles.formGroup}>
                            <label htmlFor="linked_users">Связанные пользователи:</label>
                            <select
                                id="linked_users"
                                name="linked_users"
                                multiple
                                value={selectedUserIds}
                                onChange={handleUserSelect}
                                className={styles.selectUsers}
                            >
                                {allUsers.map((u) => (
                                    <option key={u.id} value={u.id}>
                                        {`${u.first_name} ${u.last_name}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <div className={styles.buttonGroup}>
                        <button type="submit" className={styles.saveButton}>Сохранить</button>
                        <button type="button" onClick={onClose} className={styles.cancelButton}>Отмена</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditUserModal;
