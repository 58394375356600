import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isTokenValid, logout } from '../../features/auth/authSlice';
import { AppDispatch, RootState } from '../../app/store';
import axios from 'axios';
// @ts-ignore
import styles from './Header.module.css';
import Navbar from "../navbar/Navbar";
import { API_URL } from "../../features/auth/authSlice";

const Header = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const [escalationDays, setEscalationDays] = useState<number>(0);
  const [newEscalationDays, setNewEscalationDays] = useState<string | number |any>();

  const userRole = localStorage.getItem('userRole');
  console.log('User role from localStorage:', userRole);
  console.log('Is admin?:', userRole === 'ADMIN');

  useEffect(() => {
    const fetchEscalationDays = async () => {
      try {
        const response = await axios.get(`${API_URL}/groups/escalation`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        setEscalationDays(response.data);
      } catch (error) {
        console.error('Error fetching escalation days:', error);
      }
    };

    if (accessToken) {
      fetchEscalationDays();
    }
  }, [accessToken]);

  const handleSetEscalationDays = async () => {
    try {
      const daysValue = typeof newEscalationDays === 'string' 
        ? parseInt(newEscalationDays) 
        : newEscalationDays;
      
      const response = await axios.post(
        `${API_URL}/groups/escalation`,
        { days: daysValue },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setEscalationDays(daysValue);
      setNewEscalationDays('');
      window.location.reload();
    } catch (error) {
      console.error('Error setting escalation days:', error);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const isAuthenticated = accessToken && isTokenValid(accessToken);
  const isAdmin = userRole === 'ADMIN';

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (newEscalationDays === 0) {
      setNewEscalationDays('');
    }
  };

  const handleEscalationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setNewEscalationDays('');
    } else {
      setNewEscalationDays(Math.max(0, Number(value)));
    }
  };

  return (
    <header className={styles.header}>
      <h1>Мегамаркет</h1>

      
      {isAuthenticated && <Navbar/>}
      <div style={{display:'flex',gap:'1rem'}}>
      {isAuthenticated && (
        <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
          <span>Дни эскалации: {escalationDays}</span>
          
          {isAdmin && (
            <>
              <input
                type='number'
                value={newEscalationDays}
                onChange={handleEscalationChange}
                onFocus={handleFocus}
                className={styles.logoutButton1}
                style={{
                  width: '30px',
                  WebkitAppearance: 'none',
                  MozAppearance: 'textfield',
                  background:'none',
                  color:'#7b2eb0'
                }}
              />
              <button onClick={handleSetEscalationDays} className={styles.logoutButton1}>
                &#10003;
              </button>
            </>
          )}
        </div>
      )}

      {accessToken && (
        <button onClick={handleLogout} className={styles.logoutButton}>
          Выйти
        </button>
      )}</div>
    </header>
  );
};

export default Header;
