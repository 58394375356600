import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// @ts-ignore
import styles from '../auth/Auth.module.css';

const RegistrationSuccess: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(20);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state?.fromRegistration) {
      navigate('/login', { replace: true });
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          navigate('/login', { replace: true });
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate, location]);

  return (
    <div className={styles.block}>
      <div className={styles.authContainer}>
        <div className={styles.form}>
          <h2 style={{ color: '#9B38DC', marginBottom: '20px' }}>Регистрация успешно завершена!</h2>
          <p className={styles.text} style={{ fontSize: '16px', lineHeight: '1.5', textAlign: 'center', margin: '20px 0' }}>
            Ожидайте получения роли.
            <br />
            На ваш email придет уведомление.
            <br />
            <br />
            Вы будете перенаправлены на страницу входа через {timeLeft} секунд.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccess; 