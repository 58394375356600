import React from 'react';
// @ts-ignore
import styles from './ErrorModal.module.css';

interface ErrorModalProps {
  message: string;
  onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ message, onClose }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>Ошибка</h2>
        <p>{message}</p>
          <div className={styles.buttons}>
              <button className='createButton' onClick={onClose}>Закрыть</button>
          </div>

      </div>
    </div>
  );
};

export default ErrorModal;
